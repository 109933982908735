import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/Reasons';
import { getBanishmentReasons, getActRemovalReasons, getReviewRemovalReasons } from 'services/requests/reasons';

function* getBanishmentReasonsData() {
  try {
    const response = yield call(getBanishmentReasons);
    yield put(Actions.getBanishmentReasonsSuccess(response.data));
  } catch (error) {
    yield put(Actions.getBanishmentReasonsFailed(error.message));
  }
}

export function* WatchGetBanishmentReasons() {
  yield takeEvery(Types.GET_BANISHMENT_REASONS, getBanishmentReasonsData);
}

function* getActRemovalReasonsData() {
  try {
    const response = yield call(getActRemovalReasons);
    yield put(Actions.getActRemovalReasonsSuccess(response.data));
  } catch (error) {
    yield put(Actions.getActRemovalReasonsFailed(error.message));
  }
}

export function* WatchGetActRemovalReasons() {
  yield takeEvery(Types.GET_ACT_REMOVAL_REASONS, getActRemovalReasonsData);
}

function* getReviewRemovalReasonsData() {
  try {
    const response = yield call(getReviewRemovalReasons);
    yield put(Actions.getReviewRemovalReasonsSuccess(response.data));
  } catch (error) {
    yield put(Actions.getReviewRemovalReasonsFailed(error.message));
  }
}

export function* WatchGetReviewRemovalReasons() {
  yield takeEvery(Types.GET_REVIEW_REMOVAL_REASONS, getReviewRemovalReasonsData);
}

export default function* reasonsSaga() {
  yield all([fork(WatchGetBanishmentReasons), fork(WatchGetActRemovalReasons), fork(WatchGetReviewRemovalReasons)]);
}
