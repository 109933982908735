import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      alignItems: 'flex-start',
    },
  },
  selectHeader: {
    whiteSpace: 'nowrap',
  },
  select: {
    width: 150,
    backgroundColor: 'white',
  },
}));

export default useStyles;
