import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    width: 500,
    maxHeight: 300,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
}));

export default useStyles;
