import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.4rem',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: 5,
    marginBottom: 5,
  },
  addChip: {
    cursor: 'pointer',
    marginBottom: 5,
  },
}));

export default useStyles;
