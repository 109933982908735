import api from '../api';

export const getHistoryPaymentPayout = filters => {
  return api.request({
    url: `payment/history_payment_payout`,
    method: 'GET',
    params: filters,
  });
};

export const withdrawMoneyAsync = data => {
  return api.request({
    url: `payment/payout`,
    method: 'POST',
    data: data,
  });
};

export const getIgigFeeAsync = country => {
  return api.request({
    url: `igig_fee`,
    method: 'GET',
    params: country,
  });
};

export const updateIgigFeeAsync = fee => {
  return api.request({
    url: `igig_fee`,
    method: 'PUT',
    data: fee,
  });
};
