import React from 'react';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';

import {
  AccountBalance,
  AccountCircle,
  BarChart,
  Event,
  MonetizationOn,
  MusicNote,
  PlayCircleFilled,
  Settings,
  Message,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const navigationMenus = [
    {
      name: <IntlMessages id={'sidebar.dashboards'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'sidebar.dashboard.usage'} />,
          icon: <BarChart />,
          type: 'item',
          link: '/dashboard/usage',
        },
        {
          name: <IntlMessages id={'sidebar.dashboard.balance'} />,
          icon: <AccountBalance />,
          type: 'item',
          link: '/dashboard/balance',
        },
        {
          name: <IntlMessages id={'sidebar.dashboard.messages.not_answered'} />,
          icon: <Message />,
          type: 'item',
          link: '/dashboard/messagesNotAnswered',
        },
        {
          name: <IntlMessages id={'sidebar.dashboard.payment'} />,
          icon: <MonetizationOn />,
          type: 'collapse',
          children: [
            {
              name: <IntlMessages id={'sidebar.dashboard.payment.history'} />,
              type: 'item',
              link: '/dashboard/payment-history',
            },
            {
              name: <IntlMessages id={'sidebar.dashboard.payment.payout'} />,
              type: 'item',
              link: '/dashboard/payout',
            },
          ],
        },
        {
          name: <IntlMessages id={'sidebar.dashboard.users'} />,
          icon: <AccountCircle />,
          type: 'collapse',
          children: [
            {
              name: <IntlMessages id={'sidebar.dashboard.users.all'} />,
              type: 'item',
              link: '/dashboard/users',
            },
            {
              name: <IntlMessages id={'sidebar.dashboard.users.reported'} />,
              type: 'item',
              link: '/dashboard/users-reported',
            },
          ],
        },
        {
          name: <IntlMessages id={'sidebar.dashboard.acts'} />,
          icon: <PlayCircleFilled />,
          type: 'collapse',
          children: [
            {
              name: <IntlMessages id={'sidebar.dashboard.acts.all'} />,
              type: 'item',
              link: '/dashboard/acts',
            },
            {
              name: <IntlMessages id={'sidebar.dashboard.acts.reported'} />,
              type: 'item',
              link: '/dashboard/acts-reported',
            },
          ],
        },
        {
          name: <IntlMessages id={'sidebar.dashboard.bookings'} />,
          icon: <Event />,
          type: 'collapse',
          children: [
            {
              name: <IntlMessages id={'sidebar.dashboard.bookings.new'} />,
              type: 'item',
              link: '/dashboard/new-bookings',
            },
            {
              name: <IntlMessages id={'sidebar.dashboard.bookings.all'} />,
              type: 'item',
              link: '/dashboard/bookings',
            },
          ],
        },
        {
          name: <IntlMessages id={'sidebar.dashboard.stylesAndSkills'} />,
          icon: <MusicNote />,
          type: 'collapse',
          children: [
            {
              name: <IntlMessages id={'sidebar.dashboard.stylesAndSkills.styles'} />,
              type: 'item',
              link: '/dashboard/styles',
            },
            {
              name: <IntlMessages id={'sidebar.dashboard.stylesAndSkills.instruments'} />,
              type: 'item',
              link: '/dashboard/instruments',
            },
            {
              name: <IntlMessages id={'sidebar.dashboard.stylesAndSkills.skills'} />,
              type: 'item',
              link: '/dashboard/skills',
            },
          ],
        },
        {
          name: <IntlMessages id={'sidebar.dashboard.settings'} />,
          icon: <Settings />,
          type: 'collapse',
          children: [
            {
              name: <IntlMessages id={'sidebar.dashboard.settings.igig_fee'} />,
              type: 'item',
              link: '/dashboard/fee',
            },
          ],
        },
      ],
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
