export const Types = {
  SET_VIDEOS_LIST: 'SET_VIDEOS_LIST',
  ADD_VIDEO: 'ADD_VIDEO',
  DELETE_VIDEO: 'DELETE_VIDEO',
  CLEAR_LIST: 'CLEAR_LIST',
};

const INITIAL_STATE = {
  videosList: [],
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_VIDEOS_LIST:
      return {
        videosList: action.payload,
      };
    case Types.ADD_VIDEO:
      return {
        videosList: [...state.videosList, action.payload],
      };
    case Types.DELETE_VIDEO:
      const updatedList = state.videosList.map((value, indexVideo) => {
        if (action.payload === indexVideo) {
          return {
            ...value,
            delete: true,
          };
        } else {
          return value;
        }
      });
      return {
        videosList: updatedList,
      };
    case Types.CLEAR_LIST:
      return (state = INITIAL_STATE);
    default:
      return state;
  }
};

export const Actions = {
  setVideosList: list => ({
    type: Types.SET_VIDEOS_LIST,
    payload: list,
  }),
  addVideo: video => ({
    type: Types.ADD_VIDEO,
    payload: video,
  }),
  deleteVideo: index => ({
    type: Types.DELETE_VIDEO,
    payload: index,
  }),
  clearList: () => ({
    type: Types.CLEAR_LIST,
  }),
};

export default data;
