import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { Actions, Types } from '../reducers/Users';
import { getUsers, requestUser, editUser, banishUser } from 'services/requests/users';
import { Alert } from 'helpers/Alert';

const reportedUsers = axios.create({
  baseURL: '/data/users_reported.json',
});

function* getUsersData({ filters }) {
  try {
    const usersState = yield select(state => state.users);
    const response = yield call(getUsers, filters ? filters : usersState.filters);
    yield put(Actions.getUsersDataSuccess(response.data));
  } catch (error) {
    console.log(error.message);
    yield put(Actions.getUsersDataFailed(error.message));
  }
}

export function* WatchGetUsersData() {
  yield takeEvery(Types.GET_USERS_DATA, getUsersData);
}

function* fetchUserData({ payload }) {
  try {
    const response = yield call(requestUser, payload.id);

    const data = !!response.data.locations[0]
      ? {
          ...response.data,
          state: response.data.locations[0].state,
          country: response.data.locations[0].country,
          zipCode: response.data.locations[0].zipCode,
        }
      : { ...response.data, state: '-', country: '-', zipCode: '-' };

    yield put(Actions.setUserInfo(data));
  } catch (error) {
    yield put(Actions.fetchUserFailed(error.message));
  }
}

export function* WatchFetchUser() {
  yield takeEvery(Types.FETCH_USER, fetchUserData);
}

function* editUserData({ payload }) {
  try {
    const response = yield call(editUser, payload.id, payload.info);
    if (response.status === 200) {
      Alert({
        type: 'success',
        title: `Success`,
        text: `The user was successfully edited.`,
      });
      yield call(fetchUserData, { payload: { id: payload.id } });
    }
  } catch (error) {
    yield put(Actions.editUserFailed(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the editing. Please try again.`,
    });
  }
}

export function* WatchEditUser() {
  yield takeEvery(Types.EDIT_USER, editUserData);
}

function* banishUserData({ payload }) {
  try {
    const response = yield call(banishUser, payload.id, payload.request);
    if (response.status === 200) {
      Alert({
        type: 'success',
        title: `Success`,
        text: `The user was successfully ${response?.data?.status === 'active' ? 'unbanned' : 'banished'}.`,
      });
      yield put(Actions.getUsersData());
    }
  } catch (error) {
    yield put(Actions.banishUserFailed(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong. Please try again.`,
    });
  }
}

export function* WatchBanishUser() {
  yield takeEvery(Types.BANISH_USER, banishUserData);
}

function* getReportedUsersData() {
  try {
    const response = yield call(reportedUsers.get);
    yield put(Actions.getReportedUsersDataSuccess(response.data));
  } catch (error) {
    yield put(Actions.getReportedUsersDataFailed(error.message));
  }
}

export function* WatchGetReportedUsersData() {
  yield takeEvery(Types.GET_REPORTED_USERS_DATA, getReportedUsersData);
}

export default function* usersSaga() {
  yield all([
    fork(WatchGetUsersData),
    fork(WatchGetReportedUsersData),
    fork(WatchFetchUser),
    fork(WatchEditUser),
    fork(WatchBanishUser),
  ]);
}
