import { all, fork, call, takeLatest, put } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/Auth';
import { loginAsync, updatePasswordAsync, updateProfileAsync } from 'services/requests/auth';
import { Alert } from 'helpers/Alert';

function* login({ payload }) {
  try {
    const response = yield call(loginAsync, payload.email, payload.password);

    yield put(Actions.setLoginData(response.data));

    yield localStorage.setItem('token', response.data.access_token);
    yield localStorage.setItem('refresh', response.data.refresh_token);
  } catch (error) {
    yield put(Actions.loginFail());
    Alert({
      type: 'error',
      title: `Error on login attempt`,
      text: 'You typed wrong credentials or you are not an administrator user.',
    });
  }
}

function* updateProfile({ payload }) {
  try {
    yield call(loginAsync, payload.email, payload.currentPassword);

    if (payload.newPassword) yield call(updatePasswordAsync, payload.currentPassword, payload.newPassword);

    const response = yield call(updateProfileAsync, payload);

    Alert({
      type: 'success',
      title: `Success`,
      text: `The user was successfully edited.`,
    });

    yield put(Actions.updateProfileSuccess(response.data));
  } catch (error) {
    yield put(Actions.updateProfileFail(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the editing. Check your password and try again.`,
    });
  }
}

function* logout() {
  try {
    yield localStorage.removeItem('token');
    yield localStorage.removeItem('refresh');
  } catch (error) {
    console.log(error);
  }
}

function* authWatcher() {
  yield takeLatest(Types.LOGIN, login);
  yield takeLatest(Types.UPDATE_PROFILE_REQUEST, updateProfile);
  yield takeLatest(Types.RESET_STORE, logout);
}

export default function* authSaga() {
  return yield all([fork(authWatcher)]);
}
