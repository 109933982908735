export const Types = {
  GET_BANISHMENT_REASONS: 'GET_BANISHMENT_REASONS',
  GET_BANISHMENT_REASONS_SUCCESS: 'GET_BANISHMENT_REASONS_SUCCESS',
  GET_BANISHMENT_REASONS_FAIL: 'GET_BANISHMENT_REASONS_FAIL',
  GET_ACT_REMOVAL_REASONS: 'GET_ACT_REMOVAL_REASONS',
  GET_ACT_REMOVAL_REASONS_SUCCESS: 'GET_ACT_REMOVAL_REASONS_SUCCESS',
  GET_ACT_REMOVAL_REASONS_FAIL: 'GET_ACT_REMOVAL_REASONS_FAIL',
  GET_REVIEW_REMOVAL_REASONS: 'GET_REVIEW_REMOVAL_REASONS',
  GET_REVIEW_REMOVAL_REASONS_SUCCESS: 'GET_REVIEW_REMOVAL_REASONS_SUCCESS',
  GET_REVIEW_REMOVAL_REASONS_FAIL: 'GET_REVIEW_REMOVAL_REASONS_FAIL',
};

const INITIAL_STATE = {
  banishmentReasons: null,
  actRemovalReasons: null,
  reviewRemovalReasons: null,
  loading: false,
  error: null,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_BANISHMENT_REASONS:
      return { ...state, loading: true };
    case Types.GET_BANISHMENT_REASONS_SUCCESS:
      return {
        ...state,
        banishmentReasons: action.payload,
        loading: false,
        error: null,
      };
    case Types.GET_BANISHMENT_REASONS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.GET_ACT_REMOVAL_REASONS:
      return { ...state, loading: true };
    case Types.GET_ACT_REMOVAL_REASONS_SUCCESS:
      return {
        ...state,
        actRemovalReasons: action.payload,
        loading: false,
        error: null,
      };
    case Types.GET_ACT_REMOVAL_REASONS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.GET_REVIEW_REMOVAL_REASONS:
      return { ...state, loading: true };
    case Types.GET_REVIEW_REMOVAL_REASONS_SUCCESS:
      return {
        ...state,
        reviewRemovalReasons: action.payload,
        loading: false,
        error: null,
      };
    case Types.GET_REVIEW_REMOVAL_REASONS_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export const Actions = {
  getBanishmentReasons: () => ({
    type: Types.GET_BANISHMENT_REASONS,
  }),
  getBanishmentReasonsSuccess: banishmentReasons => ({
    type: Types.GET_BANISHMENT_REASONS_SUCCESS,
    payload: banishmentReasons,
  }),
  getBanishmentReasonsFailed: error => ({
    type: Types.GET_BANISHMENT_REASONS_FAIL,
    payload: error,
  }),
  getActRemovalReasons: () => ({
    type: Types.GET_ACT_REMOVAL_REASONS,
  }),
  getActRemovalReasonsSuccess: removalReasons => ({
    type: Types.GET_ACT_REMOVAL_REASONS_SUCCESS,
    payload: removalReasons,
  }),
  getActRemovalReasonsFailed: error => ({
    type: Types.GET_ACT_REMOVAL_REASONS_FAIL,
    payload: error,
  }),
  getReviewRemovalReasons: () => ({
    type: Types.GET_REVIEW_REMOVAL_REASONS,
  }),
  getReviewRemovalReasonsSuccess: removalReasons => ({
    type: Types.GET_REVIEW_REMOVAL_REASONS_SUCCESS,
    payload: removalReasons,
  }),
  getReviewRemovalReasonsFailed: error => ({
    type: Types.GET_REVIEW_REMOVAL_REASONS_FAIL,
    payload: error,
  }),
};

export default data;
