import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: 250,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 7,
      borderRadius: 5,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      borderRadius: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      borderRadius: 5,
    },
  },
  titleRoot: {
    letterSpacing: 1.25,
  },
  subTitleRoot: {
    fontSize: 12,
    color: theme.palette.text.disabled,
  },
  reviewItemBox: {
    borderBottom: '3px solid #f4f4f7',
  },
  rightDiv: {
    float: 'right',
  },
  closeButton: {
    marginRight: '.3rem',
  },
  noReviews: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.7rem',
  },
  btnRoot: {
    backgroundColor: theme.palette.lightBtn.bgColor,
    color: theme.palette.lightBtn.textColor,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.25,
    padding: '3px 10px',
    '&:hover, &:focus': {
      backgroundColor: fade(theme.palette.lightBtn.bgColor, 0.8),
      color: theme.palette.lightBtn.textColor,
    },
  },
}));

export default useStyles;
