export const Types = {
  GET_INSTRUMENTS: 'GET_INSTRUMENTS',
  SET_INSTRUMENTS: 'SET_INSTRUMENTS',
  GET_INSTRUMENTS_FAIL: 'GET_INSTRUMENTS_FAIL',
  GET_ACTIVE_STYLES: 'GET_ACTIVE_STYLES',
  GET_STYLES: 'GET_STYLES',
  SET_STYLES: 'SET_STYLES',
  GET_STYLES_FAIL: 'GET_STYLES_FAIL',
  FETCH_ACTIVE_SKILLS: 'FETCH_ACTIVE_SKILLS',
  FETCH_SKILLS: 'FETCH_SKILL',
  SET_SKILLS_INFO: 'SET_SKILLS_INFO',
  FETCH_SKILLS_FAIL: 'FETCH_SKILL_FAIL',
  ADD_SKILL: 'ADD_SKILL',
  ADD_STYLE: 'ADD_STYLE',
  ADD_INSTRUMENT: 'ADD_INSTRUMENT',
  CREATE_SPECIALTY: 'CREATE_SPECIALTY',
  CREATE_SPECIALTY_FAIL: 'CREATE_SPECIALTY_FAIL',
  UPDATE_SPECIALTY: 'UPDATE_SPECIALTY',
  UPDATE_SPECIALTY_FAIL: 'UPDATE_SPECIALTY_FAIL',
  CREATE_SUBSPECIALTY: 'CREATE_SUBSPECIALTY',
  CREATE_SUBSPECIALTY_FAIL: 'CREATE_SUBSPECIALTY_FAIL',
  UPDATE_SUBSPECIALTY: 'UPDATE_SUBSPECIALTY',
  UPDATE_SUBSPECIALTY_FAIL: 'UPDATE_SUBSPECIALTY_FAIL',
};

const INITIAL_STATE = {
  fetchedSkills: null,
  instruments: null,
  styles: null,
  loading: false,
  error: null,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_INSTRUMENTS:
      return { ...state, loading: true };
    case Types.SET_INSTRUMENTS:
      return {
        ...state,
        instruments: action.payload,
        loading: false,
        error: null,
      };
    case Types.GET_INSTRUMENTS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.GET_ACTIVE_STYLES:
      return { ...state, loading: true };
    case Types.GET_STYLES:
      return { ...state, loading: true };
    case Types.SET_STYLES:
      return {
        ...state,
        styles: action.payload,
        loading: false,
        error: null,
      };
    case Types.GET_STYLES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.FETCH_ACTIVE_SKILLS:
      return { ...state, loading: true };
    case Types.FETCH_SKILLS:
      return { ...state, loading: true };
    case Types.SET_SKILLS_INFO:
      return {
        ...state,
        fetchedSkills: action.payload,
        loading: false,
        error: null,
      };
    case Types.FETCH_SKILLS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.ADD_SKILL:
      return {
        ...state,
        fetchedSkills: [action.payload, ...state.fetchedSkills],
      };
    case Types.ADD_STYLE:
      return { ...state, styles: [action.payload, ...state.styles] };
    case Types.ADD_INSTRUMENT:
      return { ...state, instruments: [action.payload, ...state.instruments] };
    case Types.CREATE_SPECIALTY:
      return { ...state };
    case Types.CREATE_SPECIALTY_FAIL:
      return { ...state, error: action.payload };
    case Types.UPDATE_SPECIALTY:
      return { ...state };
    case Types.UPDATE_SPECIALTY_FAIL:
      return { ...state, error: action.payload };
    case Types.CREATE_SUBSPECIALTY:
      return { ...state };
    case Types.CREATE_SUBSPECIALTY_FAIL:
      return { ...state, error: action.payload };
    case Types.UPDATE_SUBSPECIALTY:
      return { ...state };
    case Types.UPDATE_SUBSPECIALTY_FAIL:
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};

export const Actions = {
  getInstruments: allLanguages => ({
    type: Types.GET_INSTRUMENTS,
    allLanguages,
  }),
  setInstruments: instruments => ({
    type: Types.SET_INSTRUMENTS,
    payload: instruments,
  }),
  getInstrumentsFailed: error => ({
    type: Types.GET_INSTRUMENTS_FAIL,
    payload: error,
  }),
  getActiveStyles: () => ({
    type: Types.GET_ACTIVE_STYLES,
  }),
  getStyles: () => ({
    type: Types.GET_STYLES,
  }),
  setStyles: styles => ({
    type: Types.SET_STYLES,
    payload: styles,
  }),
  getStylesFailed: error => ({
    type: Types.GET_STYLES_FAIL,
    payload: error,
  }),
  addStyle: style => ({
    type: Types.ADD_STYLE,
    payload: style,
  }),
  addInstrument: instrument => ({
    type: Types.ADD_INSTRUMENT,
    payload: instrument,
  }),
  fetchActiveSkills: id => ({
    type: Types.FETCH_ACTIVE_SKILLS,
    payload: { id },
  }),
  fetchSkills: id => ({
    type: Types.FETCH_SKILLS,
    payload: { id },
  }),
  setSkillsInfo: payload => ({
    type: Types.SET_SKILLS_INFO,
    payload,
  }),
  fetchSkillsFailed: error => ({
    type: Types.FETCH_SKILLS_FAIL,
    payload: error,
  }),
  addSkill: skill => ({
    type: Types.ADD_SKILL,
    payload: skill,
  }),
  createSpecialty: (type, specialty) => ({
    type: Types.CREATE_SPECIALTY,
    payload: { type, specialty },
  }),
  createSpecialtyFailed: error => ({
    type: Types.CREATE_SPECIALTY_FAIL,
    payload: error,
  }),
  updateSpecialty: (type, id, specialty) => ({
    type: Types.UPDATE_SPECIALTY,
    payload: { type, id, specialty },
  }),
  updateSpecialtyFailed: error => ({
    type: Types.UPDATE_SPECIALTY_FAIL,
    payload: error,
  }),
  createSubSpecialty: subSpecialty => ({
    type: Types.CREATE_SUBSPECIALTY,
    payload: { subSpecialty },
  }),
  createSubSpecialtyFailed: error => ({
    type: Types.CREATE_SUBSPECIALTY_FAIL,
    payload: error,
  }),
  updateSubSpecialty: (id, subSpecialty) => ({
    type: Types.UPDATE_SUBSPECIALTY,
    payload: { id, subSpecialty },
  }),
  updateSubSpecialtyFailed: error => ({
    type: Types.UPDATE_SUBSPECIALTY_FAIL,
    payload: error,
  }),
};

export default data;
