export const Types = {
  GET_NEW_USERS: 'GET_NEW_USERS',
  GET_NEW_USERS_SUCCESS: 'GET_NEW_USERS_SUCCESS',
  GET_NEW_USERS_FAIL: 'GET_NEW_USERS_FAIL',

  GET_USERS_DATA: 'GET_USERS_DATA',
  GET_USERS_DATA_SUCCESS: 'GET_USERS_DATA_SUCCESS',
  GET_USERS_DATA_FAIL: 'GET_USERS_DATA_FAIL',

  SET_USERS_FILTERS: 'SET_USERS_FILTERS',
  RESET_USERS_FILTERS: 'RESET_USERS_FILTERS',

  GET_REPORTED_USERS_DATA: 'GET_REPORTED_USERS_DATA',
  GET_REPORTED_USERS_DATA_SUCCESS: 'GET_REPORTED_USERS_DATA_SUCCESS',
  GET_REPORTED_USERS_DATA_FAIL: 'GET_REPORTED_USERS_DATA_FAIL',

  FETCH_USER: 'FETCH_USER',
  SET_USER_INFO: 'SET_USER_INFO',
  FETCH_USER_FAIL: 'FETCH_USER_FAIL',

  EDIT_USER: 'EDIT_USER',
  EDIT_USER_FAIL: 'EDIT_USER_FAIL',

  BANISH_USER: 'BANISH_USER',
  BANISH_USER_FAIL: 'BANISH_USER_FAIL',

  SET_USER_DATE_FROM: 'SET_USER_DATE_FROM',
  SET_USER_DATE_TO: 'SET_USER_DATE_TO',
  SET_ACCOUNT_COUNTRY_USER: 'SET_ACCOUNT_COUNTRY_USER',
};

const INITIAL_STATE = {
  newUsers: null,
  usersData: null,
  pagination: {
    currentPage: 1,
    limitPerPage: 25,
    totalItems: null,
    previousPage: null,
    nextPage: null,
  },
  filters: {
    page: undefined,
    limit: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phoneNumber: undefined,
    isArtist: undefined,
    status: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    accountCountry: undefined,
    city: undefined,
    state: undefined,
  },
  reportedUsersData: null,
  fetchedUser: null,
  loading: false,
  error: null,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_NEW_USERS:
      return { ...state, loading: true };
    case Types.GET_NEW_USERS_SUCCESS:
      return { ...state, newUsers: action.payload, loading: false, error: null };
    case Types.GET_NEW_USERS_FAIL:
      return { ...state, error: action.payload, loading: false };

    case Types.GET_USERS_DATA:
      return { ...state, loading: true };
    case Types.GET_USERS_DATA_SUCCESS: {
      const { data, pagination } = action.payload;

      return {
        ...state,
        usersData: data,
        loading: false,
        pagination,
        error: null,
      };
    }
    case Types.GET_USERS_DATA_FAIL:
      return { ...state, error: action.payload, loading: false };

    case Types.SET_USERS_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };

    case Types.SET_USER_DATE_FROM:
      return {
        ...state,
        filters: {
          ...state.filters,
          dateFrom: action.payload,
        },
      };
    case Types.SET_USER_DATE_TO:
      return {
        ...state,
        filters: {
          ...state.filters,
          dateTo: action.payload,
        },
      };
    case Types.SET_ACCOUNT_COUNTRY_USER:
      return {
        ...state,
        filters: {
          ...state.filters,
          accountCountry: action.payload,
        },
      };
    case Types.RESET_USERS_FILTERS:
      return {
        ...state,
        filters: {
          ...INITIAL_STATE.filters,
          page: state.pagination.currentPage,
          limit: state.pagination.limitPerPage,
        },
      };

    case Types.GET_REPORTED_USERS_DATA:
      return { ...state, loading: true };
    case Types.GET_REPORTED_USERS_DATA_SUCCESS:
      return {
        ...state,
        reportedUsersData: action.payload,
        loading: false,
        error: null,
      };
    case Types.GET_REPORTED_USERS_DATA_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.FETCH_USER:
      return { ...state, loading: true };
    case Types.SET_USER_INFO:
      return {
        ...state,
        fetchedUser: action.payload,
        loading: false,
        error: null,
      };
    case Types.FETCH_USER_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.EDIT_USER:
      return { ...state, loading: true };
    case Types.EDIT_USER_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.BANISH_USER:
      return { ...state, loading: true };
    case Types.BANISH_USER_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export const Actions = {
  getNewUsers: () => ({
    type: Types.GET_NEW_USERS,
  }),
  getNewUsersSuccess: newUsers => ({
    type: Types.GET_NEW_USERS_SUCCESS,
    payload: newUsers,
  }),
  getNewUsersFailed: error => ({
    type: Types.GET_NEW_USERS_FAIL,
    payload: error,
  }),

  getUsersData: filters => ({
    type: Types.GET_USERS_DATA,
    filters,
  }),
  getUsersDataSuccess: users => ({
    type: Types.GET_USERS_DATA_SUCCESS,
    payload: users,
  }),
  getUsersDataFailed: error => ({
    type: Types.GET_USERS_DATA_FAIL,
    payload: error,
  }),

  setUsersFilters: payload => ({
    type: Types.SET_USERS_FILTERS,
    payload,
  }),
  setUserDateFrom: payload => ({
    type: Types.SET_USER_DATE_FROM,
    payload,
  }),
  setUserDateTo: payload => ({
    type: Types.SET_USER_DATE_TO,
    payload,
  }),
  setAccountCountryUser: payload => ({
    type: Types.SET_ACCOUNT_COUNTRY_USER,
    payload,
  }),
  resetUsersFilters: () => ({
    type: Types.RESET_USERS_FILTERS,
  }),

  getReportedUsersData: () => ({
    type: Types.GET_REPORTED_USERS_DATA,
  }),
  getReportedUsersDataSuccess: reportedUsers => ({
    type: Types.GET_REPORTED_USERS_DATA_SUCCESS,
    payload: reportedUsers,
  }),
  getReportedUsersDataFailed: error => ({
    type: Types.GET_REPORTED_USERS_DATA_FAIL,
    payload: error,
  }),

  fetchUser: id => ({
    type: Types.FETCH_USER,
    payload: { id },
  }),
  setUserInfo: payload => ({
    type: Types.SET_USER_INFO,
    payload,
  }),
  fetchUserFailed: error => ({
    type: Types.FETCH_USER_FAIL,
    payload: error,
  }),

  editUser: (id, info) => ({
    type: Types.EDIT_USER,
    payload: { id, info },
  }),
  editUserFailed: error => ({
    type: Types.EDIT_USER_FAIL,
    payload: error,
  }),

  banishUser: (id, request) => ({
    type: Types.BANISH_USER,
    payload: { id, request },
  }),
  banishUserFailed: error => ({
    type: Types.BANISH_USER_FAIL,
    payload: error,
  }),
};

export default data;
