import api from '../api';

export const getBanishmentReasons = () =>
  api.request({
    url: 'banishment_reason',
    method: 'GET',
  });

export const getActRemovalReasons = () =>
  api.request({
    url: 'act_removal_reason',
    method: 'GET',
  });

export const getReviewRemovalReasons = () =>
  api.request({
    url: 'booking_review_removal_reason',
    method: 'GET',
  });
