const removedActs = JSON.parse(localStorage.getItem('removedActs'));

export const handleRemovedAct = id => {
  if (!removedActs) return localStorage.setItem('removedActs', JSON.stringify([id]));

  removedActs.push(id);

  localStorage.setItem('removedActs', JSON.stringify(removedActs));
};

export const handleDataWithRemovedActs = acts => {
  if (!removedActs || acts.length === 0) return acts;

  return acts.map(act => {
    if (removedActs.includes(act?.id)) {
      act.status === 'removed' ? clearItemFromRemoved(act?.id) : (act.status = 'removed');
      return act;
    }

    return act;
  });
};

const clearItemFromRemoved = itemToBeRemoved => {
  const updatedItems = removedActs.filter(act => act !== itemToBeRemoved);

  localStorage.setItem('removedActs', JSON.stringify(updatedItems));
};
