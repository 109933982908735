import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  customTextField: {
    [theme.breakpoints.down('xs')]: {
      height: 35,
    },
    '& input::placeholder': {
      fontSize: '15px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
  },
  filterBox: {
    border: '1px solid #A9A9A9',
    borderRadius: 5,
    marginTop: '1.2rem',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 120,
      maxHeight: 100,
      overflow: 'auto',
    },
  },
  formGroup: {
    padding: '0 .5rem',
  },
  radioGroup: {
    [theme.breakpoints.down('xs')]: {
      '& svg': {
        width: '0.5em',
        height: '0.5em',
      },
      '& .MuiTypography-body1': {
        fontSize: '12px',
      },
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  submitButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
      maxWidth: '45px',
      maxHeight: '25px',
      minWidth: '45px',
      minHeight: '25px',
    },
  },
  underlinedButton: {
    textDecoration: 'underline',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
}));

export default useStyles;
