import React from 'react';
import * as yup from 'yup';
import IntlMessages from '../../../utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { Box, Collapse, alpha, IconButton, Link } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import AuthWrapper from './AuthWrapper';
import { Alert } from '@material-ui/lab';
import { Alert as Toast } from 'helpers/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { resetPasswordAsync, sendVerificationCodeAsync } from 'services/requests/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Input } from 'components';
import { provideErrors } from 'helpers/common';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

const inputFields = [
  {
    id: 'verification-code-input',
    name: 'verificationCode',
    label: 'Verification code',
  },
  {
    id: 'new-password-input',
    name: 'newPassword',
    label: 'New password',
  },
  {
    id: 'confirm-password-input',
    name: 'confirmPassword',
    label: 'Confirm your password',
  },
];

const validationSchema = yup
  .object({
    verificationCode: yup
      .string()
      .max(4, 'Invalid verification code')
      .required('The verification code is required'),
    newPassword: yup
      .string('Password field should be a string')
      .test('len', 'The password must have at least 8 characters', pass => {
        if (!pass) {
          return true;
        }

        return pass.length >= 7;
      })
      .required(),
    confirmPassword: yup
      .string('Password confirmation field should be a string')
      .oneOf([yup.ref('newPassword'), null], "Passwords don't match"),
  })
  .required();

const ForgotPassword = ({ variant = 'default', wrapperVariant = 'default' }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({ variant });
  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async data => {
    try {
      await resetPasswordAsync(data?.verificationCode, data?.newPassword);
      Toast({
        type: 'success',
        title: `Success`,
        text: `Password successfully changed.`,
      });
      setTimeout(() => {
        history.push('/signin');
      }, 1000);
      reset();
    } catch (error) {
      console.log(error);
      Toast({
        type: 'error',
        title: `Error`,
        text: `Something went wrong. Please try again later.`,
      });
    }
  };

  const onRequestCode = async () => {
    try {
      await sendVerificationCodeAsync('admin@igig.com');
      setOpen(true);
    } catch (error) {
      Toast({
        type: 'error',
        title: `Error`,
        text: `Something went wrong. Please try again later.`,
      });
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/iGig.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Reset your password
        </Typography>
        <Typography component="div" variant="subtitle2" className={classes.titleRoot}>
          Click{' '}
          <Link
            onClick={onRequestCode}
            style={{
              cursor: 'pointer',
            }}>
            here
          </Link>{' '}
          to send a verification code to the administrator email.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          {inputFields.map(input => (
            <Box key={input.id} mb={5}>
              <Input
                id={input.id}
                type={input.name.includes('Password') ? 'password' : undefined}
                name={input.name}
                label={input.label}
                control={control}
                variant="outlined"
                error={provideErrors(errors, input.name, 'check')}
                helperText={provideErrors(errors, input.name)}
              />
            </Box>
          ))}

          <Box mb={5} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
              <IntlMessages id="appModule.resetPassword" />
            </Button>
          </Box>

          <Collapse in={open}>
            <Alert
              variant="outlined"
              severity="success"
              className={classes.alertRoot}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              A mail has been sent on your email address with the verification code.
            </Alert>
          </Collapse>
        </form>
      </Box>
    </AuthWrapper>
  );
};

export default ForgotPassword;
