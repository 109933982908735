import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 400,
  },
  tableMobile: {
    minWidth: 200,
  },
  cell: {
    display: 'flex',
    gap: '.3rem',
    alignItems: 'center',
  },
  title: {
    padding: '1rem',
  },
}));

export default useStyles;
