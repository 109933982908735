import React from 'react';
import { Box, FormControl, Select, Typography } from '@material-ui/core';
import useStyles from './styles';

const Filter = ({ id, value, handleChange, options }) => {
  const classes = useStyles();

  return <></>;
  // return (
  //   <Box className={classes.headerBox}>
  //     <Typography className={classes.selectHeader} component="h3" variant="h3">
  //       View by:
  //     </Typography>

  //     <FormControl size="small" fullWidth>
  //       <Select className={classes.select} variant="outlined" id={id} value={value} onChange={handleChange}>
  //         {!!options && options}
  //       </Select>
  //     </FormControl>
  //   </Box>
  // );
};

export default Filter;
