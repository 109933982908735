import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: 440,
    '&::-webkit-scrollbar': {
      marginTop: 10,
      width: 7,
      borderRadius: 5,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      borderRadius: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      borderRadius: 5,
    },
  },
  headCell: {
    alignItems: 'center',
  },
  bodyCell: {
    padding: 15,
    borderRight: '3px solid #f4f4f7',
    borderBottom: 'none',
    maxWidth: '3rem',
    wordWrap: 'break-word',
  },
  cellHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.5rem',
  },
  variant: {
    border: '1px solid #f4f4f7',
  },
  noResults: {
    padding: '2rem',
    textAlign: 'center',
  },
  loadingContainer: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
}));

export default useStyles;
