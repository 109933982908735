import React from 'react';
import Box from '@material-ui/core/Box';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  headerRoot: {
    paddingBottom: 8,
  },
}));

const GrowthCard = ({ data, desc, children }) => {
  const classes = useStyles();

  return (
    <CmtCard>
      <CmtCardHeader
        className={classes.headerRoot}
        title={
          <Box component="p" mb={0} color="text.secondary" fontSize={12}>
            {desc}
          </Box>
        }
        subTitle={
          <Box component="h2" color="#8DCD03">
            {!isNaN(data) && new Intl.NumberFormat('en-US').format(data)}
          </Box>
        }
      />
      {children}
    </CmtCard>
  );
};

export default GrowthCard;
