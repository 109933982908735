import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/Balance';
import { getBalanceRequest } from 'services/requests/balance';

function* getBalanceData({ payload }) {
  try {
    const response = yield call(getBalanceRequest, payload);
    yield put(Actions.getBalanceSuccess(response.data));
  } catch (error) {
    yield put(Actions.getBalanceFail(error.message));
  }
}

export function* WatchGetBalance() {
  yield takeEvery(Types.GET_BALANCE, getBalanceData);
}

export default function* balanceSaga() {
  yield all([fork(WatchGetBalance)]);
}
