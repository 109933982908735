export const Types = {
  GET_USAGE_DATA: 'GET_USAGE_DATA',
  GET_USAGE_DATA_SUCCESS: 'GET_USAGE_DATA_SUCCESS',
  GET_USAGE_DATA_FAIL: 'GET_USAGE_DATA_FAIL',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_USAGE_DATA:
      return { ...state, loading: true };
    case Types.GET_USAGE_DATA_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case Types.GET_USAGE_DATA_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export const Actions = {
  getUsageData: () => ({
    type: Types.GET_USAGE_DATA,
  }),
  getUsageDataSuccess: usage => ({
    type: Types.GET_USAGE_DATA_SUCCESS,
    payload: usage,
  }),
  getUsageDataFailed: error => ({
    type: Types.GET_USAGE_DATA_FAIL,
    payload: error,
  }),
};

export default data;
