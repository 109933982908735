import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/Acts';
import { getActs, requestAct, putAct, removeAct } from 'services/requests/acts';
import { Alert } from 'helpers/Alert';
import { handleRemovedAct } from 'helpers/storeRemovedActs';

function* getActsData({ filters }) {
  try {
    const actsState = yield select(state => state.acts);
    const response = yield call(getActs, filters ? filters : actsState.filters);
    yield put(Actions.getActsDataSuccess(response.data));
  } catch (error) {
    yield put(Actions.getActsDataFailed(error.message));
  }
}

export function* WatchGetActsData() {
  yield takeEvery(Types.GET_ACTS_DATA, getActsData);
}

function* fetchActData({ payload }) {
  try {
    const response = yield call(requestAct, payload.id);
    const specialties = [];
    response.data.specialtyProficiencies.forEach(({ specialty, level }) =>
      specialties.push({
        id: specialty.id,
        name: specialty.name.en,
        proficiency: level,
      }),
    );

    const subSpecialties = [];
    response.data.subSpecialtyProficiencies.forEach(({ subSpecialty, level }) =>
      subSpecialties.push({
        id: subSpecialty.id,
        name: subSpecialty.name.en,
        proficiency: level,
      }),
    );

    const handledData = {
      id: response.data.id,
      created_at: response.data.created_at,
      avatar: response.data.avatar,
      user_id: response.data.artist.id,
      user: response.data.artist.user.id,
      activeLocation: response.data.activeLocation,
      artist: `${response.data.artist.user.firstName} ${response.data.artist.user.lastName}`,
      baseLocation: response.data.baseLocation,
      headline: response.data.headline,
      medias: response.data.medias,
      keywords: response.data.keywords,
      name: response.data.name,
      numberOfBookings: !!response.data.numberOfBookings
        ? response.data.numberOfBookings
        : `${response.data.numberOfBookings}`,
      description: response.data.description,
      rating: response.data.rating,
      type: response.data.category.name,
      type_id: response.data.category.id,
      formation: response.data.categoryType.name,
      specialties,
      subSpecialties,
      status: response.data.status,
      accountCountry: response.data.artist.user.accountCountry,
    };

    yield put(Actions.setActInfo(handledData));
  } catch (error) {
    yield put(Actions.fetchActFailed(error.message));
  }
}

export function* WatchFetchAct() {
  yield takeEvery(Types.FETCH_ACT, fetchActData);
}

function* putActData({ payload }) {
  try {
    const response = yield call(putAct, payload.id, payload.info);
    if (response.status === 200) {
      yield put(Actions.putActSuccess());
      yield put(Actions.fetchAct(payload.id));
      Alert({
        type: 'success',
        title: `Success`,
        text: `Your changes were successfully made.`,
      });
    }
  } catch (error) {
    yield put(Actions.putActFailed(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the editing. Please try again.`,
    });
  }
}

export function* WatchPutActData() {
  yield takeEvery(Types.PUT_ACT, putActData);
}

function* removeActData({ payload }) {
  try {
    const response = yield call(removeAct, payload.id, payload.info);
    if (response.status === 200) {
      handleRemovedAct(payload.id);

      yield put(Actions.removeActSuccess(payload.id));
      Alert({
        type: 'success',
        title: `Success`,
        text: `The act was successfully removed.`,
      });
      setTimeout(() => window.location.replace('/dashboard/acts'), 2000);
    }
  } catch (error) {
    yield put(Actions.removeActFailed(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the removal.\n${error.response.data.message}`,
    });
    setTimeout(() => window.location.replace('/dashboard/acts'), 2000);
  }
}

export function* WatchRemoveActData() {
  yield takeEvery(Types.REMOVE_ACT, removeActData);
}

export default function* actsSaga() {
  yield all([fork(WatchGetActsData), fork(WatchFetchAct), fork(WatchPutActData), fork(WatchRemoveActData)]);
}
