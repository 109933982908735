export const Types = {
  GET_HISTORY_PAYMENT_PAYOUT: 'GET_HISTORY_PAYMENT_PAYOUT',
  GET_HISTORY_PAYMENT_PAYOUT_SUCCESS: 'GET_HISTORY_PAYMENT_PAYOUT_SUCCESS',
  GET_HISTORY_PAYMENT_PAYOUT_FAIL: 'GET_HISTORY_PAYMENT_PAYOUT_FAIL',

  SET_FILTERS_DATE_FROM: 'SET_FILTERS_DATE_FROM',
  SET_FILTERS_DATE_TO: 'SET_FILTERS_DATE_TO',

  PAYOUT_REQUEST: 'PAYOUT_REQUEST',
  PAYOUT_SUCCESS: 'PAYOUT_SUCCESS',
  PAYOUT_FAIL: 'PAYOUT_FAIL',

  GET_IGIG_FEE_REQUEST: 'GET_IGIG_FEE_REQUEST',
  GET_IGIG_FEE_SUCCESS: 'GET_IGIG_FEE_SUCCESS',
  GET_IGIG_FEE_FAIL: 'GET_IGIG_FEE_FAIL',

  ADJUST_IGIG_FEE_REQUEST: 'ADJUST_IGIG_FEE_REQUEST',
  ADJUST_IGIG_FEE_SUCCESS: 'ADJUST_IGIG_FEE_SUCCESS',
  ADJUST_IGIG_FEE_FAIL: 'ADJUST_IGIG_FEE_FAIL',

  SET_ACCOUNT_COUNTRY_HISTORY_PAYMENT_PAYOUT: 'SET_ACCOUNT_COUNTRY_HISTORY_PAYMENT_PAYOUT',

  RESET_FILTERS: 'RESET_FILTERS',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  pagination: {
    currentPage: 1,
    limitPerPage: 25,
    totalItems: null,
    previousPage: null,
    nextPage: null,
  },
  filters: {
    date_from: undefined,
    date_to: undefined,
    accountCountry: undefined,
  },
  igig_fee: 0,
  error: null,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_HISTORY_PAYMENT_PAYOUT:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_HISTORY_PAYMENT_PAYOUT_SUCCESS:
      const { data, pagination } = action.payload;
      return {
        ...state,
        data: data,
        pagination: pagination,
        loading: false,
      };
    case Types.GET_HISTORY_PAYMENT_PAYOUT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case Types.SET_FILTERS_DATE_FROM:
      return {
        ...state,
        filters: {
          ...state.filters,
          date_from: action.payload,
        },
      };
    case Types.SET_FILTERS_DATE_TO:
      return {
        ...state,
        filters: {
          ...state.filters,
          date_to: action.payload,
        },
      };

    case Types.SET_ACCOUNT_COUNTRY_HISTORY_PAYMENT_PAYOUT:
      return {
        ...state,
        filters: {
          ...state.filters,
          accountCountry: action.payload,
        },
      };
    case Types.RESET_FILTERS:
      return {
        ...state,
        filters: {
          date_from: undefined,
          date_to: undefined,
        },
      };

    case Types.PAYOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.PAYOUT_SUCCESS:
    case Types.PAYOUT_FAIL:
    case Types.GET_IGIG_FEE_FAIL:
    case Types.ADJUST_IGIG_FEE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_IGIG_FEE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_IGIG_FEE_SUCCESS:
    case Types.ADJUST_IGIG_FEE_SUCCESS:
      return {
        ...state,
        loading: false,
        igig_fee: action.payload,
      };

    case Types.ADJUST_IGIG_FEE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return { ...state };
  }
};

export const Actions = {
  getHistoryPaymentPayout: filters => ({
    type: Types.GET_HISTORY_PAYMENT_PAYOUT,
    filters,
  }),
  getHistoryPaymentPayoutSuccess: historyPaymentPayoutData => ({
    type: Types.GET_HISTORY_PAYMENT_PAYOUT_SUCCESS,
    payload: historyPaymentPayoutData,
  }),
  getHistoryPaymentPayoutFail: error => ({
    type: Types.GET_HISTORY_PAYMENT_PAYOUT_FAIL,
    payload: error,
  }),

  setFiltersDateFrom: payload => ({
    type: Types.SET_FILTERS_DATE_FROM,
    payload,
  }),
  setFiltersDateTo: payload => ({
    type: Types.SET_FILTERS_DATE_TO,
    payload,
  }),

  setAccountCountryHistoryPayment: payload => ({
    type: Types.SET_ACCOUNT_COUNTRY_HISTORY_PAYMENT_PAYOUT,
    payload,
  }),

  resetFilters: () => ({
    type: Types.RESET_FILTERS,
  }),

  payoutRequest: payload => ({
    type: Types.PAYOUT_REQUEST,
    payload: payload,
  }),
  payoutSuccess: () => ({
    type: Types.PAYOUT_SUCCESS,
  }),
  payoutFail: () => ({
    type: Types.PAYOUT_FAIL,
  }),

  getIgigFeeRequest: payload => ({
    type: Types.GET_IGIG_FEE_REQUEST,
    payload,
  }),
  getIgigFeeSuccess: payload => ({
    type: Types.GET_IGIG_FEE_SUCCESS,
    payload,
  }),
  getIgigFeeFail: () => ({
    type: Types.GET_IGIG_FEE_FAIL,
  }),

  adjustIgigFeeRequest: payload => ({
    type: Types.ADJUST_IGIG_FEE_REQUEST,
    payload,
  }),
  adjustIgigFeeSuccess: payload => ({
    type: Types.ADJUST_IGIG_FEE_SUCCESS,
    payload,
  }),
  adjustIgigFeeFail: () => ({
    type: Types.ADJUST_IGIG_FEE_FAIL,
  }),
};

export default data;
