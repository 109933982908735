import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Types, Actions } from '../reducers/BookingSettings';
import { getBookingSettingsByActIdAsync, updateBookingSettingsAsync } from 'services/requests/acts';
import { Alert } from 'helpers/Alert';

function* getBookingSettings({ payload }) {
  try {
    const response = yield call(getBookingSettingsByActIdAsync, payload);
    yield put(Actions.requestBookingSuccess(response.data));
  } catch (error) {
    yield put(Actions.requestBookingFail());
  }
}

function* updateBookingSettings({ payload }) {
  try {
    const response = yield call(updateBookingSettingsAsync, payload.id, payload.data);
    yield put(Actions.requestUpdateBookingSuccess(response.data));
    Alert({
      type: 'success',
      title: `Success`,
      text: `The user was successfully edited.`,
    });
  } catch (error) {
    yield put(Actions.requestUpdateBookingFail());
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the editing. Please try again.`,
    });
  }
}

export function* WatchGetBookingSettings() {
  yield takeEvery(Types.REQUEST_BOOKING, getBookingSettings);
}

export function* WatchUpdateBookingSettings() {
  yield takeEvery(Types.REQUEST_UPDATE_BOOKING, updateBookingSettings);
}

export default function* getBookingSettingsSaga() {
  yield all([fork(WatchGetBookingSettings), fork(WatchUpdateBookingSettings)]);
}
