import React from 'react';
import globalStyles from '../../../theme/GlobalCss';
import { Box } from '@material-ui/core';
import VerticalDefault from './VerticalLayouts/VerticalDefault';
import { useLocation } from 'react-router-dom';

const AppLayout = ({ children }) => {
  const location = useLocation();
  globalStyles();

  if (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/forgot-password') {
    return (
      <Box display="flex" width={1} style={{ minHeight: '100vh' }}>
        {children}
      </Box>
    );
  }

  return <VerticalDefault children={children} />;
};

export default AppLayout;
