export const Types = {
  GET_NEW_BOOKINGS: 'GET_NEW_BOOKINGS',
  GET_NEW_BOOKINGS_SUCCESS: 'GET_NEW_BOOKINGS_SUCCESS',
  GET_NEW_BOOKINGS_FAIL: 'GET_NEW_BOOKINGS_FAIL',

  GET_BOOKINGS_DATA: 'GET_BOOKINGS_DATA',
  GET_BOOKINGS_DATA_SUCCESS: 'GET_BOOKINGS_DATA_SUCCESS',
  GET_BOOKINGS_DATA_FAIL: 'GET_BOOKINGS_DATA_FAIL',

  SET_BOOKINGS_FILTERS: 'SET_BOOKINGS_FILTERS',
  RESET_BOOKINGS_FILTERS: 'RESET_BOOKINGS_FILTERS',
  SET_BOOKING_COUNTRY: 'SET_BOOKING_COUNTRY',

  FETCH_BOOKING: 'FETCH_BOOKING',
  SET_BOOKING_INFO: 'SET_BOOKING_INFO',
  FETCH_BOOKING_FAIL: 'FETCH_BOOKING_FAIL',
};

const INITIAL_STATE = {
  fetchedBooking: null,
  newBookings: null,
  data: null,
  filters: {
    page: undefined,
    limit: undefined,
    code: undefined,
    patronName: undefined,
    headline: undefined,
    creationDate: undefined,
    status: undefined,
    bookingCountry: undefined,
  },
  pagination: {
    currentPage: 1,
    limitPerPage: 25,
    totalItems: null,
    previousPage: null,
    nextPage: null,
  },
  loading: false,
  error: null,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_NEW_BOOKINGS:
      return { ...state, loading: true };
    case Types.GET_NEW_BOOKINGS_SUCCESS:
      return {
        ...state,
        newBookings: action.payload,
        loading: false,
        error: null,
      };
    case Types.GET_NEW_BOOKINGS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.GET_BOOKINGS_DATA:
      return { ...state, loading: true };
    case Types.GET_BOOKINGS_DATA_SUCCESS: {
      const { data, pagination } = action.payload;

      return { ...state, data: data, loading: false, pagination, error: null };
    }
    case Types.GET_BOOKINGS_DATA_FAIL:
      return { ...state, error: action.payload, loading: false };

    case Types.SET_BOOKINGS_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };

    case Types.RESET_BOOKINGS_FILTERS:
      return {
        ...state,
        filters: {
          ...INITIAL_STATE.filters,
          page: state.pagination.currentPage,
          limit: state.pagination.limitPerPage,
        },
      };

    case Types.SET_BOOKING_COUNTRY:
      return {
        ...state,
        filters: {
          ...state.filters,
          bookingCountry: action.payload,
        },
      };
    case Types.FETCH_BOOKING:
      return { ...state, loading: true };
    case Types.SET_BOOKING_INFO:
      return {
        ...state,
        fetchedBooking: action.payload,
        loading: false,
        error: null,
      };
    case Types.FETCH_BOOKING_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export const Actions = {
  getNewBookings: () => ({
    type: Types.GET_NEW_BOOKINGS,
  }),
  getNewBookingsSuccess: newBookings => ({
    type: Types.GET_NEW_BOOKINGS_SUCCESS,
    payload: newBookings,
  }),
  getNewBookingsFailed: error => ({
    type: Types.GET_NEW_BOOKINGS_FAIL,
    payload: error,
  }),

  getBookingsData: filters => ({
    type: Types.GET_BOOKINGS_DATA,
    filters,
  }),
  getBookingsDataSuccess: bookings => ({
    type: Types.GET_BOOKINGS_DATA_SUCCESS,
    payload: bookings,
  }),
  getBookingsDataFailed: error => ({
    type: Types.GET_BOOKINGS_DATA_FAIL,
    payload: error,
  }),

  setBookingsFilters: payload => ({
    type: Types.SET_BOOKINGS_FILTERS,
    payload,
  }),
  resetBookingsFilters: () => ({
    type: Types.RESET_BOOKINGS_FILTERS,
  }),
  setBookingCountry: payload => ({
    type: Types.SET_BOOKING_COUNTRY,
    payload,
  }),
  fetchBooking: id => ({
    type: Types.FETCH_BOOKING,
    payload: { id },
  }),
  setBookingInfo: payload => ({
    type: Types.SET_BOOKING_INFO,
    payload,
  }),
  fetchBookingFailed: error => ({
    type: Types.FETCH_BOOKING_FAIL,
    payload: error,
  }),
};

export default data;
