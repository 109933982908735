export const Types = {
  LOGIN: 'LOGIN',
  LOGIN_FAIL: 'LOGIN_FAIL',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_REMEMBER_EMAIL: 'SET_REMEMBER_EMAIL',

  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAIL: 'UPDATE_PROFILE_FAIL',

  SET_TOKENS: 'SET_TOKENS',

  RESET_STORE: 'RESET_STORE',
};

const INITIAL_STATE = {
  user: {},
  rememberEmail: false,
  access_token: undefined,
  refresh_token: undefined,
  loading: false,
};

const Auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_USER_DATA:
      return {
        ...state,
        user: action.payload.user,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        loading: false,
      };

    case Types.LOGIN:
      return {
        ...state,
        loading: true,
      };

    case Types.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
      };

    case Types.SET_REMEMBER_EMAIL:
      return {
        ...state,
        rememberEmail: !state.rememberEmail,
      };

    case Types.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.payload },
      };

    case Types.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case Types.SET_TOKENS: {
      const { access_token, refresh_token } = action.payload;

      return {
        ...state,
        access_token,
        refresh_token,
      };
    }

    case Types.RESET_STORE:
      return {
        ...state,
        access_token: undefined,
        refresh_token: undefined,
        user: {},
      };

    default:
      return state;
  }
};

export const Actions = {
  login: (email, password) => ({
    type: Types.LOGIN,
    payload: { email, password },
  }),

  loginFail: () => ({
    type: Types.LOGIN_FAIL,
  }),

  logout: () => ({
    type: Types.RESET_STORE,
  }),

  setRememberEmail: () => ({
    type: Types.SET_REMEMBER_EMAIL,
  }),

  setLoginData: payload => ({
    type: Types.SET_USER_DATA,
    payload,
  }),

  updateProfileRequest: payload => ({
    type: Types.UPDATE_PROFILE_REQUEST,
    payload,
  }),
  updateProfileSuccess: payload => ({
    type: Types.UPDATE_PROFILE_SUCCESS,
    payload,
  }),
  updateProfileFail: () => ({
    type: Types.UPDATE_PROFILE_FAIL,
  }),

  setTokens: payload => ({
    type: Types.SET_TOKENS,
    payload,
  }),
};

export default Auth;
