import React, { useState } from 'react';
import { Box, Chip, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import SelectModal from 'components/SelectModal';
import useStyles from './styles';

const colors = {
  high: '#0091FF',
  medium: '#D479EC',
  low: '#979797',
};

const ComposeTags = ({ label, readOnly, tag, setTag }) => {
  const classes = useStyles();
  const type = { Instruments: 'instruments', Styles: 'styles', Skills: 'fetchedSkills' }[label] || null;

  const [open, setOpen] = useState(false);
  const [localState, setLocalState] = useState(tag);

  React.useEffect(() => {
    setLocalState(tag);
  }, [tag]);

  const filterTo = item => {
    const updatedList = tag.filter(value => value.name !== item);
    setLocalState(updatedList);
    setTag(updatedList);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setLocalState(tag);
    handleClose();
  };

  const handleConfirm = () => {
    setTag(localState);
    handleClose();
  };

  return (
    <Box className={classes.box} mb={3} width="1">
      <Typography variant="h3">{label}</Typography>
      <Box>
        {tag.length > 0 &&
          tag.map((value, i) => (
            <Chip
              key={i}
              label={value.name}
              color="primary"
              className={classes.chip}
              style={{ backgroundColor: colors[value.proficiency] }}
              onDelete={readOnly ? null : () => filterTo(value.name)}
            />
          ))}
        {!readOnly && <Chip className={classes.addChip} label={<Add />} onClick={handleOpen} />}
        <SelectModal
          handleCancel={handleCancel}
          tag={localState}
          setTag={setLocalState}
          type={type}
          label={label.toLowerCase()}
          onConfirm={handleConfirm}
          open={open}
          handleClose={handleClose}
        />
      </Box>
    </Box>
  );
};

export default ComposeTags;
