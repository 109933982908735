import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/HistoryPaymentPayout';
import { Actions as BalanceActions } from '../reducers/Balance';
import {
  getHistoryPaymentPayout,
  getIgigFeeAsync,
  updateIgigFeeAsync,
  withdrawMoneyAsync,
} from 'services/requests/historyPaymentPayout';
import { Alert } from 'helpers/Alert';

function* getHistoryPaymentPayoutData({ filters }) {
  try {
    const response = yield call(getHistoryPaymentPayout, filters);
    yield put(Actions.getHistoryPaymentPayoutSuccess(response.data));
  } catch (error) {
    yield put(Actions.getHistoryPaymentPayoutFail(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong. Please try again.`,
    });
  }
}

function* getIgigFee({ payload }) {
  try {
    const response = yield call(getIgigFeeAsync, payload);
    yield put(Actions.getIgigFeeSuccess(response.data));
  } catch (error) {
    yield put(Actions.getIgigFeeFail(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong. Please try again.`,
    });
  }
}

function* adjustIgigFee({ payload }) {
  try {
    const response = yield call(updateIgigFeeAsync, payload);

    yield put(Actions.adjustIgigFeeSuccess(response.data));

    Alert({
      type: 'success',
      title: `Success`,
      text: `The iGig fee was successfully updated.`,
    });
  } catch (error) {
    yield put(Actions.adjustIgigFeeFail(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong. Please try again.`,
    });
  }
}

function* payout({ payload }) {
  try {
    yield call(withdrawMoneyAsync, payload);
    yield put(Actions.payoutSuccess());
    yield put(BalanceActions.getBalance({ country: payload.country }));
    Alert({
      type: 'success',
      title: `Success`,
      text: `Your payout was successfully requested. It may take a couple of minutes until stripe evaluation finishes.`,
    });
  } catch (error) {
    yield put(Actions.payoutFail());
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong. Please try again.`,
    });
  }
}

export function* WatchHistoryPaymentPayout() {
  yield takeEvery(Types.GET_HISTORY_PAYMENT_PAYOUT, getHistoryPaymentPayoutData);
  yield takeEvery(Types.GET_IGIG_FEE_REQUEST, getIgigFee);
  yield takeEvery(Types.ADJUST_IGIG_FEE_REQUEST, adjustIgigFee);
  yield takeEvery(Types.PAYOUT_REQUEST, payout);
}

export default function* historyPaymentPayoutSaga() {
  yield all([fork(WatchHistoryPaymentPayout)]);
}
