import api from '../api';

export const getActs = filters =>
  api.request({
    url: `act`,
    method: 'GET',
    params: filters,
  });

export const requestAct = id =>
  api.request({
    url: `act/${id}`,
    method: 'GET',
  });

export const putAct = (id, data) =>
  api.request({
    url: `act/${id}`,
    method: 'PUT',
    data,
  });

export const removeAct = (id, data) =>
  api.request({
    url: `act/${id}`,
    method: 'DELETE',
    data,
  });

export const getAddressByPartialAddressAsync = address =>
  api.request({
    url: `geocoding/get_address_by_partial_address`,
    method: 'POST',
    data: { address },
  });

export const getAddressByCoordinatesAsync = (latitude, longitude) =>
  api.request({
    url: `geocoding/get_address_by_coordinates`,
    method: 'POST',
    data: { latitude, longitude },
  });

export const sendAddressAsync = data =>
  api.request({
    url: 'address',
    method: 'POST',
    data,
  });

export const getBookingSettingsByActIdAsync = actId =>
  api.request({
    url: `booking_settings/by_act/${actId}`,
    method: 'GET',
  });

export const updateBookingSettingsAsync = (id, data) =>
  api.request({
    url: `booking_settings/admin/${id}`,
    method: 'PUT',
    data,
  });
