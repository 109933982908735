import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { decode, encode } from 'base-64';
import { baseURL } from '../config/baseURL';
import { refreshAuthLogic } from './refresh-token';

if (!global.btoa) {
  global.btoa = encode;
}

if (!global.atob) {
  global.atob = decode;
}

export const basicUser = 'myusername';
export const basicPass = 'password123';
const authHeader = `Basic ${encode(`${basicUser}:${basicPass}`)}`;

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(async config => {
  const headers = {
    ...config.headers,
    ...{
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  };
  const token = await localStorage.getItem('token');

  headers.Authorization = !headers.noAuth ? `Bearer ${token}` : authHeader;
  headers.Accept = 'application/json';

  return {
    ...config,
    headers,
  };
});

createAuthRefreshInterceptor(api, refreshAuthLogic);

export default api;
