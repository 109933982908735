import { all } from 'redux-saga/effects';
import authSaga from '../sagas/auth';
import analyticsSaga from '../sagas/analytics';
import usersSaga from '../sagas/users';
import actsSaga from '../sagas/acts';
import bookingsSaga from '../sagas/bookings';
import categoriesSaga from '../sagas/categories';
import usageSaga from '../sagas/usage';
import tagsSaga from '../sagas/tags';
import reasonsSaga from '../sagas/reasons';
import reviewsActSaga from '../sagas/reviewsAct';
import reviewsUserSaga from '../sagas/reviewsUser';
import balanceSaga from '../sagas/balance';
import historyPaymentPayoutSaga from './HistoryPaymentPayout';
import actAddressSearchEditSaga from './ActAddressSearchEdit';
import getBookingSettingsSaga from './BookingSettings';
import messagesNotAnsweredSaga from './MessagesNotAnswered';

export default function* rootSaga() {
  return yield all([
    authSaga(),
    analyticsSaga(),
    usersSaga(),
    actsSaga(),
    bookingsSaga(),
    categoriesSaga(),
    usageSaga(),
    tagsSaga(),
    reasonsSaga(),
    reviewsActSaga(),
    reviewsUserSaga(),
    balanceSaga(),
    historyPaymentPayoutSaga(),
    actAddressSearchEditSaga(),
    getBookingSettingsSaga(),
    messagesNotAnsweredSaga(),
  ]);
}
