import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { AccountBox } from '@material-ui/icons';
import useStyles from './styles';

function createData(row, param) {
  const typeHandlers = {
    act: row?.name,
    artist: `${row?.user?.firstName} ${row?.user?.lastName}`,
    patron: `${row?.firstName} ${row?.lastName}`,
  };

  return { name: typeHandlers[param], revenue: row.revenue };
}

export default function ArtistsTable({ label, head, data }) {
  const param = head.toLowerCase();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const rows = data?.map(row => createData(row, param));

  return (
    <TableContainer component={Paper} className={classes.root}>
      <h2 className={classes.title}>Top {label} (by revenue)</h2>
      <Table className={matches ? classes.tableMobile : classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{head}</TableCell>
            <TableCell align="right">Revenue</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell className={classes.cell} component="th" scope="row">
                <AccountBox /> {row.name}
              </TableCell>
              <TableCell align="right">
                {row.revenue.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
