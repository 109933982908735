import api from '../api';

export const getBookings = filters =>
  api.request({
    url: `booking`,
    method: 'GET',
    params: filters,
  });

export const requestBooking = id =>
  api.request({
    url: `booking/${id}`,
    method: 'GET',
  });
