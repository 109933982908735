import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/Bookings';
import { getBookings, requestBooking } from 'services/requests/bookings';
import { capitalize, dateFormatter } from 'helpers/common';

function* getBookingsData({ filters }) {
  try {
    const bookingsState = yield select(state => state.bookings);
    const response = yield call(getBookings, filters ? filters : bookingsState.filters);
    yield put(Actions.getBookingsDataSuccess(response.data));
  } catch (error) {
    yield put(Actions.getBookingsDataFailed(error.message));
  }
}

export function* WatchGetBookingsData() {
  yield takeEvery(Types.GET_BOOKINGS_DATA, getBookingsData);
}

function* fetchBookingData({ payload }) {
  try {
    const response = yield call(requestBooking, payload.id);
    const { data } = response;

    const handledData = {
      code: data.code,
      duration: data.duration,
      patron: data?.user ? `${data?.user?.firstName} ${data?.user?.lastName}` : 'Deleted user',
      status: capitalize(data.status),
      act: data.act.headline,
      creationDate: dateFormatter(data.created_at),
      placeDescription: data.placeDescription,
      patronNotes: data.patronNotes,
      actPayment: data.actPayment,
      iGigPayment: data.iGigPayment,
      totalPayment: data.totalPayment,
      paymentMethod: data.paymentMethod,
      additionalMileagePayment: data.additionalMileagePayment,
    };

    yield put(Actions.setBookingInfo(handledData));
  } catch (error) {
    yield put(Actions.fetchBookingFailed(error.message));
  }
}

export function* WatchFetchBooking() {
  yield takeEvery(Types.FETCH_BOOKING, fetchBookingData);
}

export default function* bookingsSaga() {
  yield all([fork(WatchGetBookingsData), fork(WatchFetchBooking)]);
}
