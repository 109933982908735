import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/ReviewsUser';
import { requestUserReviews, requestDeleteUserReview } from 'services/requests/reviews';
import { Alert } from 'helpers/Alert';

function* getUserReviews({ payload }) {
  try {
    const response = yield call(requestUserReviews, payload.id, payload.filters);
    yield put(Actions.getUserReviewsDataSuccess(response.data));
  } catch (error) {
    yield put(Actions.getUserReviewsDataFailed(error.message));
  }
}

function* deleteReview({ payload }) {
  try {
    yield call(requestDeleteUserReview, payload.reviewId);
    yield put(Actions.resetUserReviewsData());
    yield put(Actions.getUserReviewsData(payload.userId, { page: 1, limit: 3 }));
  } catch (error) {
    yield put(Actions.getUserReviewsDataFailed(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong. Please try again`,
    });
  }
}

export function* WatchGetUserReviewsUserData() {
  yield takeEvery(Types.GET_USER_REVIEWS_DATA, getUserReviews);
}

export function* WatchRequestDeleteUserReview() {
  yield takeEvery(Types.REQUEST_USER_DELETE_REVIEW, deleteReview);
}

export default function* reviewsUserSaga() {
  yield all([fork(WatchGetUserReviewsUserData), fork(WatchRequestDeleteUserReview)]);
}
