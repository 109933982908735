import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Dashboards from './Dashboards';
import Login from './Auth/Login';
import ForgotPassword from './Auth/ForgotPassword';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ExtraPages from './ExtraPages';
import Profile from './Profile';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { access_token } = useSelector(({ auth }) => auth);

  return (
    <Route
      {...rest}
      render={props =>
        access_token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { access_token } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/' || (access_token && location.pathname === '/signin')) {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/dashboard" component={Dashboards} />
        <RestrictedRoute path="/profile" component={Profile} />
        <Route path="/extra-pages" component={ExtraPages} />
        <Route path="/signin" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
