import api from '../api';

export const getUsers = filters =>
  api.request({
    url: `user`,
    method: 'GET',
    params: filters,
  });

export const requestUser = id =>
  api.request({
    url: `user/${id}`,
    method: 'GET',
  });

export const editUser = (id, data) =>
  api.request({
    url: `user/by_id/${id}`,
    method: 'PUT',
    data,
  });

export const editUserLocation = (id, data) =>
  api.request({
    url: `address/admin/${id}`,
    method: 'PUT',
    data,
  });

export const banishUser = (id, data) =>
  api.request({
    url: `user/ban/${id}`,
    method: 'PATCH',
    data,
  });
