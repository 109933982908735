import api from '../api';

export const getStylesTags = () =>
  api.request({
    url: 'sub_specialty',
    method: 'GET',
  });

export const getActiveStylesTags = () =>
  api.request({
    url: 'sub_specialty/active',
    method: 'GET',
    headers: { noAuth: true },
  });

export const createSpecialty = data =>
  api.request({
    url: `specialty`,
    method: 'POST',
    data,
  });

export const updateSpecialty = (id, data) =>
  api.request({
    url: `specialty/${id}`,
    method: 'PUT',
    data,
  });

export const createSubSpecialty = data =>
  api.request({
    url: `sub_specialty`,
    method: 'POST',
    data,
  });

export const updateSubSpecialty = (id, data) =>
  api.request({
    url: `sub_specialty/${id}`,
    method: 'PUT',
    data,
  });

export const requestSkills = id =>
  api.request({
    url: `specialty/by_category/${id}`,
    method: 'GET',
  });

export const requestActiveSkills = id =>
  api.request({
    url: `specialty/active_by_category/${id}`,
    method: 'GET',
    headers: { noAuth: true },
  });
