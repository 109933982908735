import api from '../api';

export const getCategories = () =>
  api.request({
    url: 'category',
    method: 'GET',
  });

export const getFormations = () =>
  api.request({
    url: 'category_type',
    method: 'GET',
  });
