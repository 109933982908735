export const Types = {
  GET_BALANCE: 'GET_BALANCE',
  GET_BALANCE_SUCCESS: 'GET_BALANCE_SUCCESS',
  GET_BALANCE_FAIL: 'GET_BALANCE_FAIL',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_BALANCE:
      return { ...state, loading: true };
    case Types.GET_BALANCE_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case Types.GET_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const Actions = {
  getBalance: payload => ({
    type: Types.GET_BALANCE,
    payload,
  }),
  getBalanceSuccess: balanceData => ({
    type: Types.GET_BALANCE_SUCCESS,
    payload: balanceData,
  }),
  getBalanceFail: error => ({
    type: Types.GET_BALANCE_FAIL,
    payload: error,
  }),
};

export default data;
