import React from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import useStyles from './styles';

const ModalWrapper = ({ openFn, onClose, children }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openFn}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={openFn}>{children}</Fade>
    </Modal>
  );
};

export default ModalWrapper;
