import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import configureStore, { history } from './redux/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';

export const { store, persistor } = configureStore();

const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <AppWrapper>
            <Switch>
              <Routes />
            </Switch>
          </AppWrapper>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </AppContextProvider>
);

export default App;
