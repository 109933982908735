import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/MessagesNotAnswered';
import { getMessagesNotAnswered } from 'services/requests/messagesNotAnswered';

function* getMessagesData({ filters }) {
  try {
    const response = yield call(getMessagesNotAnswered, filters);
    yield put(Actions.getMessagesSuccess(response.data));
  } catch (error) {
    yield put(Actions.getMessagesFailed(error.message));
  }
}

export function* WatchGetMessagesAnswered() {
  yield takeEvery(Types.GET_MESSAGES_NOT_ANSWERED, getMessagesData);
}

export default function* messagesNotAnsweredSaga() {
  yield all([fork(WatchGetMessagesAnswered)]);
}
