import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/Categories';
import { getCategories, getFormations } from 'services/requests/categories';

function* getCategoriesData() {
  try {
    const response = yield call(getCategories);
    yield put(Actions.setCategories(response.data));
  } catch (error) {
    yield put(Actions.getCategoriesFailed(error.message));
  }
}

export function* WatchGetCategories() {
  yield takeEvery(Types.GET_CATEGORIES, getCategoriesData);
}

function* getFormationsData() {
  try {
    const response = yield call(getFormations);
    yield put(Actions.setFormations(response.data));
  } catch (error) {
    yield put(Actions.getFormationsFailed(error.message));
  }
}

export function* WatchGetFormations() {
  yield takeEvery(Types.GET_FORMATIONS, getFormationsData);
}

export default function* actsSaga() {
  yield all([fork(WatchGetCategories), fork(WatchGetFormations)]);
}
