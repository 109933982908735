import React from 'react';

import { TableSortLabel } from '@material-ui/core';

const SortingLabel = ({ active, direction, column, onClick }) => {
  const handleClick = () => {
    const directionHandlers = {
      asc: () => {
        onClick(column, 'desc');
      },
      desc: () => {
        onClick(column, '');
      },
    };

    if (!Boolean(direction)) {
      return onClick(column, 'asc');
    }

    return directionHandlers[direction]();
  };

  return (
    <TableSortLabel
      active={active}
      direction={direction || 'asc'}
      onClick={handleClick}
      style={{ position: 'absolute', left: '.3rem' }}
    />
  );
};

export default SortingLabel;
