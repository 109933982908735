import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '2rem',
  },
  buttonBanish: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));

export default useStyles;
