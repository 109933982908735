import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'redux/reducers/Auth';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { alpha, Box, Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { provideErrors } from 'helpers/common';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    textAlign: 'center',
    marginBottom: 25,
    color: theme.palette.text.primary,
  },
  subtitleRoot: {
    color: theme.palette.text.primary,
    paddingTop: 10,
    paddingBottom: 10,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

export const validationSchema = yup
  .object({
    firstName: yup.string('First name field should be a string.').required('First name is a required field.'),
    lastName: yup.string('Last name field should be a string.').required('Last name is a required field.'),
    newPassword: yup
      .string('Password field should be a string')
      .test('len', 'The password must have at least 8 characters', pass => {
        if (!pass) {
          return true;
        }

        return pass.length >= 7;
      })
      .nullable(),
    newPasswordConfirm: yup
      .string('Password confirmation field should be a string')
      .oneOf([yup.ref('newPassword'), null], "Passwords don't match"),
    currentPassword: yup
      .string('Password field should be a string')
      .required('Please your current password for confirmation.'),
  })
  .required();

const Profile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const adminInfo = useSelector(state => state.auth.user);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const fillValues = React.useCallback(() => {
    for (const key in adminInfo) {
      setValue(key, adminInfo[key]);
    }
  }, [adminInfo]);

  const handleEdit = data => {
    const { firstName, lastName, email, newPassword, currentPassword } = data;

    dispatch(Actions.updateProfileRequest({ firstName, lastName, email, newPassword, currentPassword }));

    reset();
  };

  React.useEffect(() => {
    fillValues();
  }, [adminInfo]);

  return (
    <Paper>
      <Box className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Edit profile
        </Typography>
        <form onSubmit={handleSubmit(handleEdit)}>
          <Box mb={2}>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label={'First name'}
                  name="firstName"
                  fullWidth
                  value={value ?? ''}
                  onChange={onChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                  error={provideErrors(errors, 'firstName', 'check')}
                  helperText={provideErrors(errors, 'firstName')}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label={'Last name'}
                  name="lastName"
                  fullWidth
                  value={value ?? ''}
                  onChange={onChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                  error={provideErrors(errors, 'lastName', 'check')}
                  helperText={provideErrors(errors, 'lastName')}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="email"
                  label={'Email'}
                  name="email"
                  fullWidth
                  value={value ?? ''}
                  onChange={onChange}
                  margin="normal"
                  variant="filled"
                  className={classes.textFieldRoot}
                  InputProps={{ readOnly: true }}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="newPassword"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="password"
                  label={'New password'}
                  name="newPassword"
                  fullWidth
                  value={value ?? ''}
                  onChange={onChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                  error={provideErrors(errors, 'newPassword', 'check')}
                  helperText={provideErrors(errors, 'newPassword')}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="newPasswordConfirm"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="password"
                  label={'Confirm password'}
                  name="newPasswordConfirm"
                  fullWidth
                  value={value ?? ''}
                  onChange={onChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                  error={provideErrors(errors, 'newPasswordConfirm', 'check')}
                  helperText={provideErrors(errors, 'newPasswordConfirm')}
                />
              )}
            />
          </Box>

          <Typography component="div" variant="body2" className={classes.subtitleRoot}>
            Please provide your current password to confirm the changes:
          </Typography>
          <Box mb={2}>
            <Controller
              name="currentPassword"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="password"
                  label={'Current password'}
                  name="currentPassword"
                  fullWidth
                  value={value ?? ''}
                  onChange={onChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              )}
            />
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'center' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button type="submit" variant="contained" color="primary">
                Save changes
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};

export default Profile;
