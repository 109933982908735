import React from 'react';
import { TextField, Box, FormControl, FormHelperText, Select, InputLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const Input = ({
  id,
  name,
  control,
  label,
  variant,
  readOnly,
  error,
  helperText,
  multiline,
  rows,
  select,
  options,
  labelId,
  type,
}) => {
  if (!!select) {
    return (
      <Box mb={2}>
        <FormControl fullWidth variant={variant} error={error}>
          <InputLabel id={labelId}>{label}</InputLabel>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                id={id}
                labelId={labelId}
                error={error}
                label={label}
                onChange={onChange}
                value={value || ''}
                inputProps={{
                  readOnly,
                }}>
                {options}
              </Select>
            )}
          />
          {error && <FormHelperText>{label} is required.</FormHelperText>}
        </FormControl>
      </Box>
    );
  }

  return (
    <Box mb={2}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            id={id}
            error={error}
            helperText={helperText}
            label={label}
            variant={variant}
            onChange={onChange}
            value={value || ''}
            multiline={multiline}
            minRows={multiline ? rows ?? 4 : null}
            fullWidth
            InputProps={{
              readOnly: readOnly,
            }}
            type={type ?? undefined}
          />
        )}
      />
    </Box>
  );
};

export default Input;
