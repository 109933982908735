export const Types = {
  REQUEST_BOOKING: 'REQUEST_BOOKING',
  REQUEST_BOOKING_SUCCESS: 'REQUEST_BOOKING_SUCCESS',
  REQUEST_BOOKING_FAIL: 'REQUEST_BOOKING_FAIL',
  REQUEST_UPDATE_BOOKING: 'REQUEST_UPDATE_BOOKING',
  REQUEST_UPDATE_BOOKING_SUCCESS: 'REQUEST_UPDATE_BOOKING_SUCCESS',
  REQUEST_UPDATE_BOOKING_FAIL: 'REQUEST_UPDATE_BOOKING_FAIL',
};

const INITIAL_STATE = {
  loading: false,
  bookingSettings: undefined,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.REQUEST_BOOKING:
      return {
        ...state,
        loading: true,
      };
    case Types.REQUEST_BOOKING_SUCCESS:
      return {
        loading: false,
        bookingSettings: action.payload,
      };
    case Types.REQUEST_BOOKING_FAIL:
      return {
        loading: false,
        bookingSettings: undefined,
      };
    case Types.REQUEST_UPDATE_BOOKING:
      return {
        ...state,
        loading: true,
      };
    case Types.REQUEST_UPDATE_BOOKING_SUCCESS:
      return {
        loading: false,
        bookingSettings: action.payload,
      };
    case Types.REQUEST_UPDATE_BOOKING_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const Actions = {
  requestBooking: actId => ({
    type: Types.REQUEST_BOOKING,
    payload: actId,
  }),
  requestBookingSuccess: payload => ({
    type: Types.REQUEST_BOOKING_SUCCESS,
    payload,
  }),
  requestBookingFail: () => ({
    type: Types.REQUEST_BOOKING_FAIL,
  }),
  requestUpdateBooking: (id, data) => ({
    type: Types.REQUEST_UPDATE_BOOKING,
    payload: { id, data },
  }),
  requestUpdateBookingSuccess: payload => ({
    type: Types.REQUEST_UPDATE_BOOKING_SUCCESS,
    payload,
  }),
  requestUpdateBookingFail: () => ({
    type: Types.REQUEST_UPDATE_BOOKING_FAIL,
  }),
};

export default data;
