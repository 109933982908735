import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const Dashboards = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/acts`} />
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/users`} />
        <Route path={`${requestedUrl}/usage`} component={lazy(() => import('./Usage'))} />
        <Route path={`${requestedUrl}/payment-history`} component={lazy(() => import('./HistoryPaymentPayout'))} />
        <Route path={`${requestedUrl}/payout`} component={lazy(() => import('./Payout'))} />
        <Route path={`${requestedUrl}/balance`} component={lazy(() => import('./Balance'))} />
        <Route path={`${requestedUrl}/messagesNotAnswered`} component={lazy(() => import('./MessagesNotAnswered'))} />
        <Route path={`${requestedUrl}/users`} component={lazy(() => import('./Users'))} />
        <Route path={`${requestedUrl}/new-users`} component={lazy(() => import('./Users/New'))} />
        <Route exact path={`${requestedUrl}/user/:id`} component={lazy(() => import('./UserDetails'))} />
        <Route exact path={`${requestedUrl}/user-register-act/:id`} component={lazy(() => import('./UserRegisterAct'))} />
        <Route path={`${requestedUrl}/users-reported`} component={lazy(() => import('./ReportedUsers'))} />
        <Route exact path={`${requestedUrl}/user-report/:id`} component={lazy(() => import('./ReportedDetails'))} />
        <Route path={`${requestedUrl}/acts`} component={lazy(() => import('./Acts'))} />
        <Route path={`${requestedUrl}/new-acts`} component={lazy(() => import('./Acts/New'))} />
        <Route exact path={`${requestedUrl}/act/:id`} component={lazy(() => import('./ActsDetails'))} />
        <Route path={`${requestedUrl}/bookings`} component={lazy(() => import('./Bookings'))} />
        <Route path={`${requestedUrl}/new-bookings`} component={lazy(() => import('./Bookings/New'))} />
        <Route exact path={`${requestedUrl}/booking/:id`} component={lazy(() => import('./BookingDetails'))} />
        <Route path={`${requestedUrl}/skills`} component={lazy(() => import('./Skills'))} />
        <Route path={`${requestedUrl}/styles`} component={lazy(() => import('./Styles'))} />
        <Route path={`${requestedUrl}/instruments`} component={lazy(() => import('./Instruments'))} />
        <Route path={`${requestedUrl}/fee`} component={lazy(() => import('./IgigFee'))} />
        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Dashboards;
