import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/Tags';
import {
  getStylesTags,
  getActiveStylesTags,
  requestSkills,
  requestActiveSkills,
  createSpecialty,
  createSubSpecialty,
  updateSpecialty,
  updateSubSpecialty,
} from 'services/requests/tags';
import { getCategories } from 'services/requests/categories';
import { Alert } from 'helpers/Alert';

function* getInstruments({ allLanguages }) {
  const instruments = [];
  try {
    const categories = yield call(getCategories);

    const musicCategory = categories.data.find(category => category.name === 'Music');
    const response = yield call(requestSkills, musicCategory.id);
    response.data.forEach(({ id, name, status }) => instruments.push({ id, name: allLanguages ? name : name.en, status }));
    yield put(Actions.setInstruments(instruments));
  } catch (error) {
    yield put(Actions.getInstrumentsFailed(error.message));
  }
}

export function* WatchGetInstrumentsData() {
  yield takeEvery(Types.GET_INSTRUMENTS, getInstruments);
}

function* getStyles() {
  const styles = [];
  try {
    const response = yield call(getStylesTags);

    response.data.forEach(({ id, name, status }) => styles.push({ id, name: { en: name?.en, es: name?.es }, status }));
    yield put(Actions.setStyles(styles));
  } catch (error) {
    yield put(Actions.getStylesFailed(error.message));
  }
}

export function* WatchGetStylesData() {
  yield takeEvery(Types.GET_STYLES, getStyles);
}

function* getActiveStyles() {
  const activeStyles = [];
  try {
    const response = yield call(getActiveStylesTags);
    response.data.forEach(({ id, name, status }) => activeStyles.push({ id, name: name.en, status }));
    yield put(Actions.setStyles(activeStyles));
  } catch (error) {
    yield put(Actions.getStylesFailed(error.message));
  }
}

export function* WatchGetActiveStylesData() {
  yield takeEvery(Types.GET_ACTIVE_STYLES, getActiveStyles);
}

function* fetchSkills({ payload }) {
  const skills = [];
  try {
    const response = yield call(requestSkills, payload.id);
    response.data.forEach(({ id, name, status }) => skills.push({ id, name: name, status }));
    yield put(Actions.setSkillsInfo(skills));
  } catch (error) {
    yield put(Actions.fetchSkillsFailed(error.message));
  }
}

export function* WatchFetchSkillsData() {
  yield takeEvery(Types.FETCH_SKILLS, fetchSkills);
}

function* fetchActiveSkills({ payload }) {
  const activeSkills = [];
  try {
    const response = yield call(requestActiveSkills, payload.id);

    response.data.forEach(({ id, name, status }) => activeSkills.push({ id, name: name.en, status }));
    yield put(Actions.setSkillsInfo(activeSkills));
  } catch (error) {
    yield put(Actions.fetchSkillsFailed(error.message));
  }
}

export function* WatchFetchActiveSkillsData() {
  yield takeEvery(Types.FETCH_ACTIVE_SKILLS, fetchActiveSkills);
}

function* postSpecialty({ payload }) {
  try {
    yield call(createSpecialty, payload.specialty);
    payload.type === 'instrument'
      ? yield put(Actions.getInstruments(true))
      : yield call(fetchSkills, {
          payload: { id: payload.specialty.category.id },
        });
    Alert({
      type: 'success',
      title: `Success`,
      text: `Your ${payload.type} was successfully created.`,
    });
  } catch (error) {
    yield put(Actions.createSpecialtyFailed(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the creation. Please try again.`,
    });
  }
}

export function* WatchPostSpecialty() {
  yield takeEvery(Types.CREATE_SPECIALTY, postSpecialty);
}

function* postSubSpecialty({ payload }) {
  try {
    yield call(createSubSpecialty, payload.subSpecialty);
    yield call(getStyles);
    Alert({
      type: 'success',
      title: `Success`,
      text: `Your style was successfully created.`,
    });
  } catch (error) {
    yield put(Actions.createSubSpecialtyFailed(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the creation. Please try again.`,
    });
  }
}

export function* WatchPostSubSpecialty() {
  yield takeEvery(Types.CREATE_SUBSPECIALTY, postSubSpecialty);
}

function* putSpecialty({ payload }) {
  try {
    const response = yield call(updateSpecialty, payload.id, payload.specialty);
    if (response.status === 200) {
      payload.type === 'instrument'
        ? yield put(Actions.getInstruments(true))
        : yield call(fetchSkills, {
            payload: { id: payload.specialty.category.id },
          });
      Alert({
        type: 'success',
        title: `Success`,
        text: `Your changes were successfully made.`,
      });
    }
  } catch (error) {
    yield put(Actions.updateSpecialtyFailed(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the editing. Please try again.`,
    });
  }
}

export function* WatchPutSpecialty() {
  yield takeEvery(Types.UPDATE_SPECIALTY, putSpecialty);
}

function* putSubSpecialty({ payload }) {
  try {
    yield call(updateSubSpecialty, payload.id, payload.subSpecialty);
    yield call(getStyles);
    Alert({
      type: 'success',
      title: `Success`,
      text: `Your changes were successfully made.`,
    });
  } catch (error) {
    yield put(Actions.updateSubSpecialtyFailed(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the edition. Please try again.`,
    });
  }
}

export function* WatchPutSubSpecialty() {
  yield takeEvery(Types.UPDATE_SUBSPECIALTY, putSubSpecialty);
}

export default function* tagsSaga() {
  yield all([
    fork(WatchGetInstrumentsData),
    fork(WatchGetStylesData),
    fork(WatchFetchSkillsData),
    fork(WatchFetchActiveSkillsData),
    fork(WatchGetActiveStylesData),
    fork(WatchPostSpecialty),
    fork(WatchPostSubSpecialty),
    fork(WatchPutSpecialty),
    fork(WatchPutSubSpecialty),
  ]);
}
