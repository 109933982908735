import React from 'react';
import { Button, Fade, Typography } from '@material-ui/core';
import ModalWrapper from '../ModalWrapper';
import List from '../List';
import useStyles from './styles';

const SelectModal = ({ open, handleClose, type, label, onConfirm, tag, setTag, handleCancel }) => {
  const classes = useStyles();
  return (
    <ModalWrapper openFn={open} onClose={handleClose}>
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography className={classes.modalTitle} variant="h1">
            Select the {label}
          </Typography>
          <List tag={tag} setTag={setTag} type={type} />
          <div className={classes.buttonsWrapper}>
            <Button onClick={handleCancel} variant="outlined">
              Cancel
            </Button>
            <Button onClick={onConfirm} color="primary" variant="contained">
              Confirm
            </Button>
          </div>
        </div>
      </Fade>
    </ModalWrapper>
  );
};

export default SelectModal;
