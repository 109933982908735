import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Auth from './Auth';
import Analytics from './Analytics';
import Users from './Users';
import Acts from './Acts';
import Bookings from './Bookings';
import Categories from './Categories';
import Usage from './Usage';
import Tags from './Tags';
import Reasons from './Reasons';
import ReviewsAct from './ReviewsAct';
import ReviewsUser from './ReviewsUser';
import Balance from './Balance';
import HistoryPaymentPayout from './HistoryPaymentPayout';
import ActAddressSearchEdit from './ActAddressSearchEdit';
import BookingSettings from './BookingSettings';
import ActVideosList from './ActVideosList';
import MessagesNotAnswered from './MessagesNotAnswered';

export default history =>
  combineReducers({
    router: connectRouter(history),
    analytics: Analytics,
    auth: Auth,
    users: Users,
    acts: Acts,
    bookings: Bookings,
    categories: Categories,
    usage: Usage,
    tags: Tags,
    reasons: Reasons,
    reviewsAct: ReviewsAct,
    reviewsUser: ReviewsUser,
    balance: Balance,
    historyPaymentPayout: HistoryPaymentPayout,
    actAddressSearchEdit: ActAddressSearchEdit,
    bookingSettings: BookingSettings,
    actVideosList: ActVideosList,
    messagesNotAnswered: MessagesNotAnswered,
  });
