import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import useStyles from './styles';

export default function CostsTable({ paymentInfo }) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <h2 className={classes.title}>Costs</h2>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableCell className={classes.root} component="th" scope="row">
              Act:
            </TableCell>
            <TableCell className={classes.root} align="right">
              {Number(paymentInfo.actPayment).toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.root} component="th" scope="row">
              iGig service:
            </TableCell>
            <TableCell className={classes.root} align="right">
              {Number(paymentInfo.iGigPayment).toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.root} component="th" scope="row">
              Extra mileage:
            </TableCell>
            <TableCell className={classes.root} align="right">
              {Number(paymentInfo.mileage).toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.root} component="th" scope="row">
              Total:
            </TableCell>
            <TableCell className={classes.root} align="right">
              {Number(paymentInfo.total).toFixed(2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
