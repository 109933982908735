import React, { Fragment } from 'react';
import { Actions as ReviewsActions } from 'redux/reducers/ReviewsAct';
import { Actions as ReviewsUserActions } from 'redux/reducers/ReviewsUser';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CmtCard from '@coremat/CmtCard';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtList from '@coremat/CmtList';
import ReviewItem from './ReviewItem';
import useStyles from './styles';
import { TvOffOutlined } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

const Reviews = ({ type, label, handleReviewRemoval }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const reviewsAct = useSelector(state => state.reviewsAct.data);
  const paginationReviewsAct = useSelector(state => state.reviewsAct.pagination);
  const actLoading = useSelector(state => state.reviewsAct.loading);

  const reviewsUser = useSelector(state => state.reviewsUser.data);
  const paginationReviewsUser = useSelector(state => state.reviewsUser.pagination);
  const userLoading = useSelector(state => state.reviewsUser.loading);

  const { id } = useParams();

  const loadingData = () => {
    return (
      <Box width={1} display="flex" color="text.secondary" justifyContent="center" p={2}>
        <CircularProgress size={16} />
        <Box component="span" ml={2}>
          Loading...
        </Box>
      </Box>
    );
  };

  const loadMoreActReviews = () => {
    if (paginationReviewsAct.nextPage !== null) {
      dispatch(ReviewsActions.getReviewsData(id, { page: paginationReviewsAct.nextPage, limit: 3 }));
    }
  };

  const loadMoreUserReviews = () => {
    if (paginationReviewsUser.nextPage !== null) {
      dispatch(ReviewsUserActions.getUserReviewsData(id, { page: paginationReviewsUser.nextPage, limit: 3 }));
    }
  };

  React.useEffect(() => {
    loadingData();
  }, [actLoading, userLoading]);

  return (
    <Fragment>
      {type === 'act' ? (
        reviewsAct?.length > 0 ? (
          <>
            <Typography style={{ margin: '2rem 0' }} component="div" variant="h1">
              {label}
            </Typography>
            <CmtCard>
              <CmtCardContent>
                <Box className={classes.root}>
                  <CmtList
                    data={reviewsAct}
                    renderRow={(item, index) => (
                      <ReviewItem
                        type={type}
                        handleReviewRemoval={handleReviewRemoval}
                        key={index}
                        item={item}
                        classes={classes}
                      />
                    )}
                  />
                  {reviewsAct.length < paginationReviewsAct.totalItems && (
                    <Box p={6} textAlign="center" onClick={() => loadMoreActReviews()}>
                      {actLoading ? (
                        <Box width={1} display="flex" color="text.secondary" justifyContent="center" p={2}>
                          <CircularProgress size={16} />
                          <Box component="span" ml={2}>
                            Loading...
                          </Box>
                        </Box>
                      ) : (
                        <Button className={classes.btnRoot}>Load More</Button>
                      )}
                    </Box>
                  )}
                </Box>
              </CmtCardContent>
            </CmtCard>
          </>
        ) : (
          <>
            <Typography style={{ margin: '2rem 0' }} component="div" variant="h1">
              {label}
            </Typography>
            <Typography className={classes.noReviews} variant="subtitle2" gutterBottom>
              <TvOffOutlined /> This act doesn't have any review.
            </Typography>
          </>
        )
      ) : reviewsUser?.length > 0 ? (
        <>
          <Typography style={{ margin: '2rem 0' }} component="div" variant="h1">
            {label}
          </Typography>
          <CmtCard>
            <CmtCardContent>
              <Box className={classes.root}>
                <CmtList
                  data={reviewsUser}
                  renderRow={(item, index) => (
                    <ReviewItem
                      type={type}
                      handleReviewRemoval={handleReviewRemoval}
                      key={index}
                      item={item}
                      classes={classes}
                    />
                  )}
                />
                {reviewsUser.length < paginationReviewsUser.totalItems && (
                  <Box p={6} textAlign="center" onClick={() => loadMoreUserReviews()}>
                    {userLoading ? (
                      <Box width={1} display="flex" color="text.secondary" justifyContent="center" p={2}>
                        <CircularProgress size={16} />
                        <Box component="span" ml={2}>
                          Loading...
                        </Box>
                      </Box>
                    ) : (
                      <Button className={classes.btnRoot}>Load More</Button>
                    )}
                  </Box>
                )}
              </Box>
            </CmtCardContent>
          </CmtCard>
        </>
      ) : (
        <>
          <Typography style={{ margin: '2rem 0' }} component="div" variant="h1">
            {label}
          </Typography>
          <Typography className={classes.noReviews} variant="subtitle2" gutterBottom>
            <TvOffOutlined /> This user doesn't have any review.
          </Typography>
        </>
      )}
    </Fragment>
  );
};

export default Reviews;
