export const Types = {
  SEARCH_ADDRESS: 'SEARCH_ADDRESS',
  LOCATION_SEARCH_SUCCESS: 'LOCALTION_SEARCH_SUCCESS',
  LOCATION_SEARCH_FAIL: 'LOCATION_SEARCH_FAIL',
  SET_ID_FOR_SAVE: 'SET_ID_FOR_SAVE',
  REQUEST_SAVE_LOCATION: 'REQUEST_SAVE_LOCATION',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  SET_LOADING: 'SET_LOADING',
};

const INITIAL_STATE = {
  loading: false,
  resultOfSearch: undefined,
  idForSave: undefined,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SEARCH_ADDRESS:
      return {
        ...state,
        loading: true,
      };
    case Types.LOCATION_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        resultOfSearch: action.payload,
      };
    case Types.LOCATION_SEARCH_FAIL:
      return {
        idForSave: undefined,
        loading: false,
        resultOfSearch: undefined,
      };
    case Types.SET_ID_FOR_SAVE:
      return {
        ...state,
        idForSave: action.payload,
      };
    case Types.CLEAR_SEARCH:
      return {
        idForSave: undefined,
        loading: false,
        resultOfSearch: undefined,
      };
    case Types.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export const Actions = {
  searchLocation: address => ({
    type: Types.SEARCH_ADDRESS,
    payload: { address },
  }),
  locationSearchSuccess: payload => ({
    type: Types.LOCATION_SEARCH_SUCCESS,
    payload,
  }),
  locationSearchFail: () => ({
    type: Types.LOCATION_SEARCH_FAIL,
  }),
  requestSaveLocation: address => ({
    type: Types.REQUEST_SAVE_LOCATION,
    payload: { address },
  }),
  setIdForSave: payload => ({
    type: Types.SET_ID_FOR_SAVE,
    payload,
  }),
  clearSearch: () => ({
    type: Types.CLEAR_SEARCH,
  }),
  setLoading: payload => ({
    type: Types.SET_LOADING,
    payload,
  }),
};

export default data;
