export const Types = {
  GET_CATEGORIES: 'GET_CATEGORIES',
  SET_CATEGORIES: 'SET_CATEGORIES',
  GET_CATEGORIES_FAIL: 'GET_CATEGORIES_FAIL',
  GET_FORMATIONS: 'GET_FORMATIONS',
  SET_FORMATIONS: 'SET_FORMATIONS',
  GET_FORMATIONS_FAIL: 'GET_FORMATIONS_FAIL',
};

const INITIAL_STATE = {
  categories: null,
  formations: null,
  loading: false,
  error: null,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_CATEGORIES:
      return { ...state, loading: true };
    case Types.SET_CATEGORIES:
      return { ...state, categories: action.payload };
    case Types.GET_CATEGORIES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.GET_FORMATIONS:
      return { ...state, loading: true };
    case Types.SET_FORMATIONS:
      return { ...state, formations: action.payload };
    case Types.GET_FORMATIONS_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export const Actions = {
  getCategories: () => ({
    type: Types.GET_CATEGORIES,
  }),
  setCategories: categories => ({
    type: Types.SET_CATEGORIES,
    payload: categories,
  }),
  getCategoriesFailed: error => ({
    type: Types.GET_CATEGORIES_FAIL,
    payload: error,
  }),
  getFormations: () => ({
    type: Types.GET_FORMATIONS,
  }),
  setFormations: formations => ({
    type: Types.SET_FORMATIONS,
    payload: formations,
  }),
  getFormationsFailed: error => ({
    type: Types.GET_FORMATIONS_FAIL,
    payload: error,
  }),
};

export default data;
