import api from '../api';

export const loginAsync = (email, password) =>
  api.request({
    url: 'auth/login/admin',
    method: 'POST',
    data: {
      email,
      password,
      isSocialMedia: false,
    },
    skipAuthRefresh: true,
  });

export const updateProfileAsync = data =>
  api.request({
    url: `user`,
    method: 'PATCH',
    data,
  });

export const updatePasswordAsync = (password, newPassword) =>
  api.request({
    url: `user/update-password`,
    method: 'PATCH',
    data: { password, newPassword },
  });

export const sendVerificationCodeAsync = email =>
  api.request({
    url: `auth/send/no_auth`,
    method: 'POST',
    data: { email },
    headers: { noAuth: true },
  });

export const resetPasswordAsync = (code, newPassword) =>
  api.request({
    url: `user/reset-password-admin`,
    method: 'PATCH',
    data: { code, newPassword },
    headers: { noAuth: true },
    skipAuthRefresh: true,
  });
