import { Actions } from '../redux/reducers/Auth';
import axios from 'axios';
import { baseURL } from '../config/baseURL';
import { Alert } from 'helpers/Alert';

let store;

export function injectStore(_store) {
  store = _store;
}

export const refreshAuthLogic = async failedRequest => {
  const refresh = await localStorage.getItem('refresh');

  if (!refresh) {
    store.dispatch(Actions.logout());
    Alert({
      type: 'error',
      title: `Error`,
      text: `There was a problem with your authentication. Please log in again`,
    });
    window.location.replaceState(null, 'Login', '/signin');
    return Promise.reject(new Error('There was a problem with your authentication. Please log in again'));
  }

  return axios
    .post(`${baseURL}/auth/refresh`, {}, { headers: { Authorization: `Bearer ${refresh}` } })
    .then(async response => {
      await localStorage.setItem('token', response.data.access_token);
      await localStorage.setItem('refresh', response.data.refresh_token);

      store.dispatch(
        Actions.setTokens({
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
        }),
      );

      failedRequest.response.config.headers.Authorization = `Bearer ${response?.data?.access_token}`;
      return Promise.resolve();
    })
    .catch(() => {
      store.dispatch(Actions.logout());
      Alert({
        type: 'error',
        title: `Error`,
        text: `There was a problem with your authentication. Please log in again`,
      });
      window.location.replaceState(null, 'Login', '/signin');
      return Promise.reject(new Error('There was a problem with your authentication. Please log in again'));
    });
};
