import { handleDataWithRemovedActs } from 'helpers/storeRemovedActs';

export const Types = {
  GET_NEW_ACTS: 'GET_NEW_ACTS',
  GET_NEW_ACTS_SUCCESS: 'GET_NEW_ACTS_SUCCESS',
  GET_NEW_ACTS_FAIL: 'GET_NEW_ACTS_FAIL',

  GET_ACTS_DATA: 'GET_ACTS_DATA',
  GET_ACTS_DATA_SUCCESS: 'GET_ACTS_DATA_SUCCESS',
  GET_ACTS_DATA_FAIL: 'GET_ACTS_DATA_FAIL',

  SET_ACT_FILTERS: 'SET_ACT_FILTERS',
  RESET_ACT_FILTERS: 'RESET_ACT_FILTERS',

  FETCH_ACT: 'FETCH_ACT',
  SET_ACT_INFO: 'SET_ACT_INFO',
  FETCH_ACT_FAIL: 'FETCH_ACT_FAIL',

  PUT_ACT: 'PUT_ACT',
  PUT_ACT_SUCCESS: 'PUT_ACT_SUCCESS',
  PUT_ACT_FAIL: 'PUT_ACT_FAIL',

  REMOVE_ACT: 'REMOVE_ACT',
  REMOVE_ACT_SUCCESS: 'REMOVE_ACT_SUCCESS',
  REMOVE_ACT_FAIL: 'REMOVE_ACT_FAIL',

  SET_ACT_DATE_FROM: 'SET_ACT_DATE_FROM',
  SET_ACT_DATE_TO: 'SET_ACT_DATE_TO',
  SET_ACCOUNT_COUNTRY_ACT: 'SET_ACCOUNT_COUNTRY_ACT',
};

const INITIAL_STATE = {
  newActs: null,
  data: null,
  pagination: {
    currentPage: 1,
    limitPerPage: 25,
    totalItems: null,
    previousPage: null,
    nextPage: null,
  },
  filters: {
    page: undefined,
    limit: undefined,
    headline: undefined,
    categoryId: undefined,
    status: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    accountCountry: undefined,
    city: undefined,
    state: undefined,
  },
  fetchedAct: null,
  loading: false,
  error: null,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_NEW_ACTS:
      return { ...state, loading: true };
    case Types.GET_NEW_ACTS_SUCCESS:
      return { ...state, newActs: action.payload, loading: false, error: null };
    case Types.GET_NEW_ACTS_FAIL:
      return { ...state, error: action.payload, loading: false };

    case Types.GET_ACTS_DATA:
      return { ...state, loading: true };
    case Types.GET_ACTS_DATA_SUCCESS: {
      const { data, pagination } = action.payload;

      const dataWithRemovedActs = handleDataWithRemovedActs(data);

      return {
        ...state,
        data: dataWithRemovedActs,
        loading: false,
        pagination,
        error: null,
      };
    }
    case Types.GET_ACTS_DATA_FAIL:
      return { ...state, error: action.payload, loading: false };

    case Types.SET_ACT_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };

    case Types.SET_ACT_DATE_FROM:
      return {
        ...state,
        filters: {
          ...state.filters,
          dateFrom: action.payload,
        },
      };
    case Types.SET_ACT_DATE_TO:
      return {
        ...state,
        filters: {
          ...state.filters,
          dateTo: action.payload,
        },
      };
    case Types.SET_ACCOUNT_COUNTRY_ACT:
      return {
        ...state,
        filters: {
          ...state.filters,
          accountCountry: action.payload,
        },
      };
    case Types.RESET_ACT_FILTERS:
      return {
        ...state,
        filters: {
          ...INITIAL_STATE.filters,
          page: state.pagination.currentPage,
          limit: state.pagination.limitPerPage,
        },
      };

    case Types.FETCH_ACT:
      return { ...state, loading: true };
    case Types.SET_ACT_INFO:
      return {
        ...state,
        fetchedAct: action.payload,
        loading: false,
        error: null,
      };
    case Types.FETCH_ACT_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.PUT_ACT:
      return { ...state, loading: true };
    case Types.PUT_ACT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.PUT_ACT_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.REMOVE_ACT:
      return { ...state, loading: true };
    case Types.REMOVE_ACT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.REMOVE_ACT_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return { ...state };
  }
};

export const Actions = {
  getNewActs: () => ({
    type: Types.GET_NEW_ACTS,
  }),
  getNewActsSuccess: newActs => ({
    type: Types.GET_NEW_ACTS_SUCCESS,
    payload: newActs,
  }),
  getNewActsFailed: error => ({
    type: Types.GET_NEW_ACTS_FAIL,
    payload: error,
  }),

  getActsData: filters => ({
    type: Types.GET_ACTS_DATA,
    filters,
  }),
  getActsDataSuccess: actsData => ({
    type: Types.GET_ACTS_DATA_SUCCESS,
    payload: actsData,
  }),
  getActsDataFailed: error => ({
    type: Types.GET_ACTS_DATA_FAIL,
    payload: error,
  }),

  setActFilters: payload => ({
    type: Types.SET_ACT_FILTERS,
    payload,
  }),
  setActDateFrom: payload => ({
    type: Types.SET_ACT_DATE_FROM,
    payload,
  }),
  setActDateTo: payload => ({
    type: Types.SET_ACT_DATE_TO,
    payload,
  }),
  setAccountCountryAct: payload => ({
    type: Types.SET_ACCOUNT_COUNTRY_ACT,
    payload,
  }),
  resetActFilters: () => ({
    type: Types.RESET_ACT_FILTERS,
  }),

  fetchAct: id => ({
    type: Types.FETCH_ACT,
    payload: { id },
  }),
  setActInfo: payload => ({
    type: Types.SET_ACT_INFO,
    payload,
  }),
  fetchActFailed: error => ({
    type: Types.FETCH_ACT_FAIL,
    payload: error,
  }),

  putAct: (id, info) => ({
    type: Types.PUT_ACT,
    payload: { id, info },
  }),
  putActSuccess: () => ({
    type: Types.PUT_ACT_SUCCESS,
  }),
  putActFailed: error => ({
    type: Types.PUT_ACT_FAIL,
    payload: error,
  }),

  removeAct: (id, info) => ({
    type: Types.REMOVE_ACT,
    payload: { id, info },
  }),
  removeActSuccess: id => ({
    type: Types.REMOVE_ACT_SUCCESS,
    payload: { id },
  }),
  removeActFailed: error => ({
    type: Types.REMOVE_ACT_FAIL,
    payload: error,
  }),
};

export default data;
