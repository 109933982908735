import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from '../reducers';
import sagas from '../sagas';
import { injectStore } from 'services/refresh-token';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const persistConfig = {
  key: 'igig',
  storage: storage,
  whitelist: ['auth'],
  stateReconciler: autoMergeLevel2,
};
const pReducer = persistReducer(persistConfig, reducers(history));

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, logger, routeMiddleware];

function configureStore(initialState = {}) {
  const store = createStore(pReducer, initialState, applyMiddleware(...middlewares));
  const persistor = persistStore(store);
  sagaMiddleware.run(sagas);
  injectStore(store);
  return { store, persistor };
}
export default configureStore;
export { history };
