import React from 'react';
import Box from '@material-ui/core/Box';
import UserInfo from './UserInfo';
import useStyles from './styles';

const ReviewItem = ({ type, item, handleReviewRemoval }) => {
  const classes = useStyles();

  return (
    <Box className={classes.reviewItemBox} mb={6}>
      <UserInfo type={type} handleReviewRemoval={handleReviewRemoval} item={item} />
      <Box ml={14}>
        <Box mt={2} mb={5} component="p" color="text.secondary">
          {item.comment}
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewItem;
