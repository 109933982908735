import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/ReviewsAct';
import { requestActReviews, requestDeleteActReview } from 'services/requests/reviews';
import { Alert } from 'helpers/Alert';

function* getReviewsData({ payload }) {
  try {
    const response = yield call(requestActReviews, payload.id, payload.filters);
    yield put(Actions.getReviewsDataSuccess(response.data));
  } catch (error) {
    yield put(Actions.getReviewsDataFailed(error.message));
  }
}

function* deleteActReview({ payload }) {
  try {
    yield call(requestDeleteActReview, payload.reviewId);
    yield put(Actions.resetReviewsData());
    yield put(Actions.getReviewsData(payload.actId, { page: 1, limit: 3 }));
  } catch (error) {
    yield put(Actions.getReviewsDataFailed(error.message));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong. Please try again.`,
    });
  }
}

export function* WatchGetReviewsActData() {
  yield takeEvery(Types.GET_REVIEWS_DATA, getReviewsData);
}

export function* WatchRequestDeleteActReview() {
  yield takeEvery(Types.REQUEST_ACT_DELETE_REVIEW, deleteActReview);
}

export default function* reviewsActSaga() {
  yield all([fork(WatchGetReviewsActData), fork(WatchRequestDeleteActReview)]);
}
