import React, { Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import CmtAvatar from '@coremat/CmtAvatar';
import ReviewsMenu from './Menu';
import useStyles from './styles';

const UserInfo = ({ type, item, handleReviewRemoval }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <ReviewsMenu handleReviewRemoval={handleReviewRemoval} variant={type} reviewId={item.id} />
      <Box display="flex" alignItems="center">
        <Box mr={4}>
          <CmtAvatar size={40} src={item.avatar} />
        </Box>

        <Box>
          {type === 'act' ? (
            <Typography className={classes.titleRoot} component="div" variant="h5">
              {item.user.firstName} says:
            </Typography>
          ) : (
            <Typography className={classes.titleRoot} component="div" variant="h5">
              {item.act.name} says:
            </Typography>
          )}
          {type === 'report' ? (
            <Typography component="div" variant="caption">
              Reason: Prohibited material
            </Typography>
          ) : (
            <Rating size="small" name="read-only" value={item.rating} readOnly />
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default UserInfo;
