import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import ModalWrapper from '../ModalWrapper';
import useStyles from './styles';

const ModalComponent = ({
  open,
  edit,
  handleClose,
  handleAdd,
  handleRemoval,
  variant,
  type,
  label,
  submit,
  currentEdit,
  handlePut,
  reasons,
  reviewId,
  deleteAct,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [listVal, setListVal] = React.useState({ en: '', es: '' });
  const editInputRefEN = React.useRef();
  const editInputRefES = React.useRef();
  const { id, name, status } = !!currentEdit && currentEdit;
  const [checkbox, setCheckbox] = React.useState('');

  const userLoading = useSelector(state => state.reviewsUser.loading);

  const handleCheckbox = e => {
    setCheckbox(e.target.value);
  };

  const handleValue = e => {
    setValue(e.target.value);
  };

  const handleChange = (e, language) => {
    setListVal({ ...listVal, [language]: e.target.value });
  };

  const handleSave = () => {
    submit();
    handleClose();
  };

  const handleConfirm = () => {
    handleAdd(listVal);
    setListVal({ en: '', es: '' });
    return handleClose();
  };

  const handleCancel = () => {
    setCheckbox(status ?? '');
    setValue('');
    handleClose();
  };

  const handleEdit = () => {
    if (!editInputRefEN?.current?.value || !editInputRefES?.current?.value) {
      return;
    } else {
      const item = {
        id,
        name: { en: editInputRefEN.current.value, es: editInputRefES.current.value },
        status: checkbox,
      };
      handlePut(item);
      setCheckbox(status ?? '');
      setListVal({ en: '', es: '' });
      return handleClose();
    }
  };

  React.useEffect(() => {
    setListVal({ en: !!name?.en ? name?.en : '', es: !!name?.es ? name.es : '' });
  }, [name, status]);

  React.useEffect(() => {
    if (!!status) setCheckbox(status);
  }, [status]);

  if (variant === 'edit') {
    return (
      <ModalWrapper openFn={edit} onClose={handleClose}>
        <div className={classes.paper}>
          <Typography variant="h1">Edit {label}</Typography>
          <Box mb={5} mt={5}>
            <TextField
              inputRef={editInputRefEN}
              fullWidth
              label="Name (EN)"
              size="small"
              onChange={e => handleChange(e, 'en')}
              defaultValue={name?.en}
              variant="outlined"
            />
          </Box>
          <Box mb={5} mt={5}>
            <TextField
              inputRef={editInputRefES}
              fullWidth
              label="Name (ES)"
              size="small"
              onChange={e => handleChange(e, 'es')}
              defaultValue={name?.es}
              variant="outlined"
            />
          </Box>
          <FormControl component="fieldset">
            <FormLabel>Status</FormLabel>
            <RadioGroup
              row
              aria-label="status"
              defaultValue={status}
              value={checkbox}
              onChange={handleCheckbox}
              name="row-radio-buttons-group">
              <FormControlLabel value="active" control={<Radio />} label="Active" />
              <FormControlLabel value="disabled" control={<Radio />} label="Disabled" />
            </RadioGroup>
          </FormControl>
          <div className={classes.editWrapper}>
            <Button onClick={handleCancel} variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={listVal.en.trim() === '' || listVal.es.trim() === ''}
              onClick={handleEdit}
              color="primary"
              variant="contained">
              Save changes
            </Button>
          </div>
        </div>
      </ModalWrapper>
    );
  }

  if (variant === 'create') {
    return (
      <ModalWrapper openFn={open} onClose={handleClose}>
        <div className={classes.paper}>
          <Typography className={classes.modalTitle} variant="h1">
            Create new {label}
          </Typography>
          <Typography mt={6} variant="subtitle2">
            Please enter the name of the new {label}:
          </Typography>
          <Box mb={2} mt={2}>
            <TextField
              fullWidth
              size="small"
              value={listVal.en}
              onChange={e => handleChange(e, 'en')}
              variant="outlined"
              placeholder="English"
            />
          </Box>
          <Box mb={2} mt={2}>
            <TextField
              fullWidth
              size="small"
              value={listVal.es}
              onChange={e => handleChange(e, 'es')}
              variant="outlined"
              placeholder="Spanish"
            />
          </Box>
          <div className={classes.commonWrapper}>
            <Button
              onClick={() => {
                setListVal({ en: '', es: '' });
                handleClose();
              }}
              variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={listVal.en.trim() === '' || listVal.es.trim() === ''}
              onClick={handleConfirm}
              color="primary"
              variant="contained">
              Confirm
            </Button>
          </div>
        </div>
      </ModalWrapper>
    );
  }

  if (variant === 'save') {
    return (
      <ModalWrapper openFn={open} onClose={handleClose}>
        <div className={classes.paper}>
          <Typography className={classes.modalTitle} variant="h1">
            Save changes
          </Typography>
          <Typography mt={6} variant="subtitle2">
            Are you sure you want to change the information of this {type === 'act' ? 'act' : 'user'}?
          </Typography>
          <div className={classes.commonWrapper}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              Confirm
            </Button>
          </div>
        </div>
      </ModalWrapper>
    );
  }

  if (variant === 'removeReviewUser') {
    return (
      <ModalWrapper openFn={open} onClose={handleClose}>
        <div className={classes.paper}>
          <Typography className={classes.modalTitle} variant="h1">
            Remove review
          </Typography>
          <Typography mt={6} variant="subtitle2">
            This action can not be undone.
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle2">
            Do you really want to delete this review?
          </Typography>
          <div className={classes.commonWrapper}>
            {!userLoading ? (
              <>
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleRemoval(reviewId);
                    handleClose();
                  }}
                  color="primary"
                  variant="contained">
                  Confirm
                </Button>
              </>
            ) : (
              <Box width={1} display="flex" color="text.secondary" justifyContent="center" p={2}>
                <CircularProgress size={16} />
                <Box component="span" ml={2}>
                  Loading...
                </Box>
              </Box>
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  }

  if (variant === 'removeReviewAct') {
    return (
      <ModalWrapper openFn={open} onClose={handleClose}>
        <div className={classes.paper}>
          <Typography className={classes.modalTitle} variant="h1">
            Remove review
          </Typography>
          <Typography mt={6} variant="subtitle2">
            This action can not be undone.
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle2">
            Do you really want to delete this review?
          </Typography>
          <div className={classes.commonWrapper}>
            {!userLoading ? (
              <>
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleRemoval(reviewId);
                    handleClose();
                  }}
                  color="primary"
                  variant="contained">
                  Confirm
                </Button>
              </>
            ) : (
              <Box width={1} display="flex" color="text.secondary" justifyContent="center" p={2}>
                <CircularProgress size={16} />
                <Box component="span" ml={2}>
                  Loading...
                </Box>
              </Box>
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper openFn={open} onClose={handleClose}>
      <div className={classes.paper}>
        <Typography className={classes.modalTitle} variant="h1">
          {type === 'act' ? `Remove ${label}` : 'Banish user'}
        </Typography>
        <Typography mt={6} variant="subtitle2">
          This action can not be undone.
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle2">
          Please {type === 'act' ? 'select a reason to remove it' : 'provide a reason for the banishment'} and confirm the
          action.
        </Typography>
        <FormControl className={classes.control} fullWidth variant="outlined">
          {type === 'act' ? (
            <Select
              className={classes.select}
              id="type-select"
              fullWidth
              value={value}
              onChange={handleValue}
              labelId="type-select-label">
              {!!reasons &&
                reasons.map(reason => (
                  <MenuItem key={reason.id} value={reason.id}>
                    {reason.reason}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <TextField id="banishment-reason-input" fullWidth multiline value="" onChange={() => {}} />
          )}
        </FormControl>
        <div className={classes.commonWrapper}>
          <Button onClick={handleCancel} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={value === ''}
            onClick={() => {
              handleRemoval(value);
              handleClose();
            }}
            type="submit"
            color="primary"
            variant="contained">
            Confirm
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalComponent;
