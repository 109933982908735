import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'redux/reducers/Reasons';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert, Close } from '@material-ui/icons';
import Modal from '../Modal';
import useStyles from './styles';

export default function ReviewsMenu({ handleReviewRemoval, variant, reviewId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [modal, setModal] = React.useState(false);

  const reasons = useSelector(state => state.reasons.reviewRemovalReasons);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    dispatch(Actions.getReviewRemovalReasons());
    handleClose();
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <Fragment>
      <div className={classes.rightDiv}>
        <IconButton
          aria-label="more"
          id="menu-button"
          aria-controls="long-menu"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          id="menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              width: '23ch',
            },
          }}>
          <MenuItem onClick={handleOpenModal}>
            <Close className={classes.closeButton} />
            Remove this review
          </MenuItem>
        </Menu>
      </div>
      <Modal
        variant={variant === 'user' ? 'removeReviewUser' : 'removeReviewAct'}
        reviewId={reviewId}
        type="act"
        label="review"
        open={modal}
        reasons={reasons}
        handleClose={handleCloseModal}
        handleRemoval={handleReviewRemoval}
      />
    </Fragment>
  );
}
