import { all, put, call, fork, takeEvery, select } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/ActAddressSearchEdit';
import { getAddressByPartialAddressAsync, getAddressByCoordinatesAsync, sendAddressAsync } from 'services/requests/acts';
import { Alert } from 'helpers/Alert';

function* getLocation({ payload }) {
  try {
    const response = yield call(getAddressByPartialAddressAsync, payload.address);
    if (response.data.length === 0) {
      yield put(Actions.setLoading(false));
    } else {
      const responseByCoordinates = yield call(
        getAddressByCoordinatesAsync,
        response.data[0].geometry.location.lat,
        response.data[0].geometry.location.lng,
      );

      const { number, street, city, state, country, zip_code } = responseByCoordinates.data;

      if (!number || !street || !city || !state || !country || !zip_code) {
        yield put(Actions.setLoading(false));
        return;
      }
      const data = {
        streetAddress: `${number} ${street}`,
        city,
        state,
        country,
        zipCode: zip_code,
        latitude: response.data[0].geometry.location.lat,
        longitude: response.data[0].geometry.location.lng,
      };
      yield put(Actions.locationSearchSuccess(data));
    }
  } catch (error) {
    yield put(Actions.locationSearchFail());
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the editing. Please try again.`,
    });
  }
}

function* editBaseLocationAct({ payload }) {
  try {
    const response = yield call(sendAddressAsync, payload.address);
    yield put(Actions.setIdForSave(response.data.id));
  } catch (error) {
    yield put(Actions.setLoading(false));
    Alert({
      type: 'error',
      title: `Error`,
      text: `Something went wrong with the editing. Please try again.`,
    });
  }
}

export function* WatchActAddressSearchEdit() {
  yield takeEvery(Types.SEARCH_ADDRESS, getLocation);
}

export function* WatchEditBaseLocationAct() {
  yield takeEvery(Types.REQUEST_SAVE_LOCATION, editBaseLocationAct);
}

export default function* actAddressSearchEditSaga() {
  yield all([fork(WatchActAddressSearchEdit), fork(WatchEditBaseLocationAct)]);
}
