import React from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import GrowthCard from '@jumbo/components/Common/GrowthCard';

const labelProvider = {
  registrationPerWeek: 'Registrations per week',
  bookingPerWeek: 'Bookings per week',
  visitsPerDay: 'Visits per day',
  visitsPerWeek: 'Visits per week',
};

const reducer = (prevValue, currValue) => prevValue + currValue;

const averageCalculator = data => {
  return data?.map(({ value }) => Number(value)).reduce(reducer, 0) / data?.length;
};

const Chart = ({ data, label }) => {
  data.forEach(value => {
    value.date = value.date.slice(0, 10).replace(/-/g, '/');
    value[labelProvider[label]?.split(' ')[0]] = value.value;
  });

  return (
    <GrowthCard data={averageCalculator(data)} desc={labelProvider[label]}>
      <ResponsiveContainer width="100%" height={75}>
        <LineChart data={data} margin={{ top: 5, right: 5, left: 5, bottom: 10 }}>
          <XAxis dataKey={'date'} hide />
          <Tooltip labelStyle={{ color: 'black' }} cursor={false} />
          <Line
            dataKey={labelProvider[label]?.split(' ')[0]}
            strokeWidth={2}
            stroke="#8DCD03"
            dot={{ stroke: '#8DCD03', strokeWidth: 1 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </GrowthCard>
  );
};

export default Chart;
