export const capitalize = string => {
  return `${string[0].toUpperCase()}${string.slice(1)}`;
};

export const dateFormatter = date => {
  return new Date(date).toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const provideErrors = (errors, param, type) => {
  const checker = errors[param];
  if (type === 'check') {
    return !!checker ? true : false;
  } else {
    return !!checker ? checker.message : null;
  }
};

export const convertMoney = value => {
  let valueConverted = value
    ? value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    : '-';
  return valueConverted;
};

export const convertNameStatus = value => {
  let nameConverted = value.replace(/_/g, ' ');
  nameConverted = nameConverted[0].toUpperCase() + nameConverted.substring(1);
  return nameConverted;
};
