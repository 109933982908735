import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  box: {
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  headerBox: {
    display: 'flex',
    padding: '2rem',
    alignItems: 'center',
    maxWidth: '50%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      alignItems: 'center',
    },
  },
  selectHeader: {
    width: '100%',
  },
  select: {
    backgroundColor: 'white',
  },
  addButton: {
    color: 'blue',
  },
  addButtonLabel: {
    marginTop: '.15rem',
  },
}));

export default useStyles;
