import React from 'react';
import { Button, Fade, Typography } from '@material-ui/core';
import ModalWrapper from '../ModalWrapper';
import SwitcherList from '../Switcher';
import useStyles from './styles';

const ProficiencyModal = ({ type, open, handleClose, item, tag, setTag }) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(null);

  const handleConfirm = val => {
    if (!val) {
      return handleClose();
    } else {
      handleClose();
      setChecked(null);
      const updatedItem = { ...item, proficiency: checked };
      const updatedList = tag.filter(value => value !== item);
      setTag([...updatedList, updatedItem]);
    }
  };
  return (
    <ModalWrapper openFn={open} onClose={handleClose}>
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography className={classes.modalTitle} variant="h1">
            Select perform
          </Typography>
          <SwitcherList type={type} checked={checked} setChecked={setChecked} />
          <div className={classes.buttonsWrapper}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={() => handleConfirm(item)} color="primary" variant="contained">
              Ok
            </Button>
          </div>
        </div>
      </Fade>
    </ModalWrapper>
  );
};

export default ProficiencyModal;
