export const Types = {
  GET_MESSAGES_NOT_ANSWERED: 'GET_MESSAGES_NOT_ANSWERED',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAIL: 'GET_MESSAGES_FAIL',
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
  pagination: {
    currentPage: 1,
    limitPerPage: 25,
    totalItems: null,
    previousPage: null,
    nextPage: null,
  },
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_MESSAGES_NOT_ANSWERED:
      return { ...state, loading: true };
    case Types.GET_MESSAGES_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
        pagination: action.payload.pagination,
        error: null,
      };
    case Types.GET_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export const Actions = {
  getMessages: filters => ({
    type: Types.GET_MESSAGES_NOT_ANSWERED,
    filters,
  }),
  getMessagesSuccess: messages => ({
    type: Types.GET_MESSAGES_SUCCESS,
    payload: messages,
  }),
  getMessagesFailed: error => ({
    type: Types.GET_MESSAGES_FAIL,
    payload: error,
  }),
};

export default data;
