import api from '../api';

export const requestActReviews = (actId, filters) =>
  api.request({
    url: `booking_review/${actId}`,
    method: 'GET',
    params: filters,
    headers: { noAuth: true },
  });

export const requestUserReviews = (actId, filters) =>
  api.request({
    url: `user_review/${actId}`,
    method: 'GET',
    params: filters,
  });

export const requestDeleteUserReview = reviewId =>
  api.request({
    url: `user_review/${reviewId}`,
    method: 'DELETE',
  });

export const requestDeleteActReview = (reviewId, data) =>
  api.request({
    url: `booking_review/${reviewId}`,
    method: 'DELETE',
    data,
  });
