import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/Analytics';
import { getAnalytics } from 'services/requests/analytics';

function* getAnalyticsData({ payload }) {
  try {
    const response = yield call(getAnalytics, payload);
    yield put(Actions.getAnalyticsSuccess(response.data));
  } catch (error) {
    yield put(Actions.getAnalyticsFailed(error.message));
  }
}

export function* WatchGetAnalytics() {
  yield takeEvery(Types.GET_ANALYTICS, getAnalyticsData);
}

export default function* analyticsSaga() {
  yield all([fork(WatchGetAnalytics)]);
}
