import React from 'react';
import { Button, Box } from '@material-ui/core';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';

const ButtonsGroup = ({ handleOpen, handleEdit, label, variant, isBanished, isUser, userId }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/dashboard/user-register-act/${userId}`);
  };

  const Save = () => (
    <Box className={classes.box} mt={!label === 'act' ? 2 : 16}>
      <Button id="cancel-button" onClick={handleEdit} variant="outlined">
        Cancel
      </Button>
      <Button id="save-button" onClick={handleOpen} color="primary" variant="contained">
        Save
      </Button>
    </Box>
  );

  const Default = () => (
    <Box className={classes.box} mt={!label === 'act' ? 2 : 16}>
      <Button id="banish-button" onClick={handleOpen} className={classes.buttonBanish} variant="contained">
        {label === 'act' ? 'Remove act' : isBanished ? 'Unban user' : 'Banish user'}
      </Button>
      <Button id="edit-button" onClick={handleEdit} color="primary" variant="contained">
        Edit {label}
      </Button>

      {isUser && (
        <Button id="register-act" onClick={() => handleClick()} color="secondary" variant="contained">
          Register Act
        </Button>
      )}
    </Box>
  );

  return { save: <Save /> }[variant] || <Default />;
};

export default ButtonsGroup;
