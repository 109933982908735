import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: 440,
    borderBottom: 'none',
  },
  root: {
    borderBottom: 'none',
    padding: 12,
  },
  title: {
    padding: '1rem',
  },
}));

export default useStyles;
