export const Types = {
  GET_USER_REVIEWS_DATA: 'GET_USER_REVIEWS_DATA',
  GET_USER_REVIEWS_DATA_SUCCESS: 'GET_USER_REVIEWS_DATA_SUCCESS',
  GET_USER_REVIEWS_DATA_FAIL: 'GET_USER_REVIEWS_DATA_FAIL',
  REQUEST_USER_DELETE_REVIEW: 'REQUEST_USER_DELETE_REVIEW',
  DELETE_USER_REVIEW: 'DELETE_USER_REVIEW',
  RESET_USER_REVIEWS_DATA: 'RESET_USER_REVIEWS_DATA',
  SET_USER_MODAL: 'SET_USER_MODAL',
};

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  showModal: false,
  pagination: {
    currentPage: 1,
    limitPerPage: 3,
    totalItems: null,
    previousPage: null,
    nextPage: null,
  },
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_USER_REVIEWS_DATA:
      return { ...state, loading: true };
    case Types.GET_USER_REVIEWS_DATA_SUCCESS: {
      const { data, pagination } = action.payload;
      return { data: [...state.data, ...data], pagination: pagination, loading: false, error: null };
    }
    case Types.GET_USER_REVIEWS_DATA_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.REQUEST_USER_DELETE_REVIEW:
      return {
        ...state,
        loading: true,
      };
    case Types.DELETE_USER_REVIEW: {
      const newData = state.data.filter(item => item.id !== action.payload);
      return {
        ...state,
        data: newData,
        loading: false,
      };
    }
    case Types.RESET_USER_REVIEWS_DATA:
      return (state = INITIAL_STATE);
    case Types.SET_USER_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };
    default:
      return { ...state };
  }
};

export const Actions = {
  getUserReviewsData: (id, filters) => ({
    type: Types.GET_USER_REVIEWS_DATA,
    payload: {
      id,
      filters,
    },
  }),
  getUserReviewsDataSuccess: reviews => ({
    type: Types.GET_USER_REVIEWS_DATA_SUCCESS,
    payload: reviews,
  }),
  getUserReviewsDataFailed: error => ({
    type: Types.GET_USER_REVIEWS_DATA_FAIL,
    payload: error,
  }),
  resetUserReviewsData: () => ({
    type: Types.RESET_USER_REVIEWS_DATA,
  }),
  requestDeleteUserReview: (reviewId, userId) => ({
    type: Types.REQUEST_USER_DELETE_REVIEW,
    payload: {
      reviewId: reviewId,
      userId: userId,
    },
  }),
  deleteUserReview: reviewId => ({
    type: Types.DELETE_USER_REVIEW,
    payload: reviewId,
  }),
  setUserModal: payload => ({
    type: Types.SET_USER_MODAL,
    payload,
  }),
};

export default data;
