import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '.05px solid transparent',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: '2rem 3rem',
  },
  modalTitle: {
    paddingBottom: '2rem',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    paddingTop: '1rem',
  },
}));

export default useStyles;
