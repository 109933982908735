import React from 'react';
import { Box, Button, FormControl, Grid, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import { TableComponent, Modal } from 'components';
import useStyles from './styles';

const ChangingArea = ({ type, items, handleAdd, categories, edit, handleFetch, handleType, handlePut, loading }) => {
  const [open, setOpen] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [currentEdit, setCurrentEdit] = React.useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleEdit = data => {
    setCurrentEdit(data);
    setEditing(true);
  };

  const classes = useStyles();

  const labeller = type.toLowerCase().slice(0, -1);

  const createData = data => {
    const { name, status } = data;
    return {
      item: name.en,
      status: `${status[0].toUpperCase()}${status.slice(1)}`,
      actions: <Edit style={{ cursor: 'pointer' }} onClick={() => handleEdit(data)} />,
    };
  };

  const rows = !!items && items.map(row => createData(row));

  const columns = [
    {
      id: 'item',
      label: type,
      width: 170,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Status',
      width: 170,
      align: 'center',
    },
    {
      id: 'actions',
      label: 'Actions',
      width: 100,
      align: 'center',
    },
  ];

  if (type === 'Skills') {
    return (
      <React.Fragment>
        <Box className={classes.headerBox}>
          <Typography variant="h3" component="h3" className={classes.selectHeader}>
            Select an act type to edit:
          </Typography>

          <FormControl size="small" fullWidth>
            <Select className={classes.select} variant="outlined" id="skills-select" value={edit} onChange={handleType}>
              {!!categories &&
                categories.map(({ id, name }, i) => {
                  if (name === 'Music') return null;
                  return (
                    <MenuItem key={i} value={name} onClick={() => handleFetch(id)}>
                      {name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
        {!!edit && (
          <Grid container justifyContent="flex-end">
            <Box className={classes.box}>
              <Button className={classes.addButton} onClick={() => setOpen(true)}>
                <Add /> <p className={classes.addButtonLabel}>Create a new {labeller}</p>
              </Button>
            </Box>
          </Grid>
        )}
        {!!edit && <TableComponent loading={loading} type="foo" rows={rows} columns={columns} />}
        <Modal
          handlePut={handlePut}
          currentEdit={currentEdit}
          label={labeller}
          variant="edit"
          edit={editing}
          handleClose={handleCancel}
        />
        <Modal label={labeller} handleAdd={handleAdd} variant="create" open={open} handleClose={handleClose} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Grid container justifyContent="flex-end">
        <Box className={classes.box}>
          <Button className={classes.addButton} onClick={() => setOpen(true)}>
            <Add /> <p className={classes.addButtonLabel}>Create a new {labeller}</p>
          </Button>
        </Box>
      </Grid>

      <Paper>
        <Grid item xs={12} lg={12}>
          <TableComponent loading={loading} type="foo" rows={rows} columns={columns} />
        </Grid>
        <Modal
          handlePut={handlePut}
          currentEdit={currentEdit}
          label={labeller}
          variant="edit"
          edit={editing}
          handleClose={handleCancel}
        />
        <Modal label={labeller} handleAdd={handleAdd} variant="create" open={open} handleClose={handleClose} />
      </Paper>
    </React.Fragment>
  );
};

export default ChangingArea;
