import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Actions, Types } from '../reducers/Usage';
import axios from 'axios';

const usage = axios.create({
  baseURL: '/data/usage.json',
});

function* getUsageData() {
  try {
    const response = yield call(usage.get);
    yield put(Actions.getUsageDataSuccess(response.data));
  } catch (error) {
    yield put(Actions.getUsageDataFailed(error.message));
  }
}

export function* WatchGetUsageData() {
  yield takeEvery(Types.GET_USAGE_DATA, getUsageData);
}

export default function* usageSaga() {
  yield all([fork(WatchGetUsageData)]);
}
