import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 320,
    backgroundColor: theme.palette.background.default,
  },
}));

export default useStyles;
