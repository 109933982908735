import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import useStyles from './styles';

const Fallback = () => {
  const classes = useStyles();

  return (
    <Box className={classes.circularProgressRoot}>
      <CircularProgress />
    </Box>
  );
};

export default Fallback;
