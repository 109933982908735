import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  buttonBanish: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '.05px solid transparent',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: '2rem 3rem',
  },
  modalTitle: {
    paddingBottom: '2rem',
  },
  subtitle: {
    maxWidth: '20rem',
  },
  control: {
    margin: '1.5rem 0',
  },
  select: {
    height: '3.2rem',
  },
  editWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    paddingTop: '1rem',
  },
  commonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '1rem',
  },
}));

export default useStyles;
