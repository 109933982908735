import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'redux/reducers/Tags';
import { Box, CircularProgress, List, ListItem, ListItemText, Paper } from '@material-ui/core';

import useStyles from './styles';

export default function ListComponent({ type, tag, setTag }) {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.tags.loading);
  const items = useSelector(state => (type === 'styles' ? state.tags.styles : state.tags.fetchedSkills));
  React.useEffect(() => {
    if (!items && type === 'styles') {
      dispatch(Actions.getActiveStyles());
    }
  }, [dispatch, items, type]);

  const classes = useStyles();

  const [item, setItem] = React.useState('');

  const handleSelect = val => {
    setItem(val);

    const updatedItem = { ...val };
    const updatedList = tag.filter(value => value !== item);
    setTag([...updatedList, updatedItem]);
  };

  if (!!loading || !items) {
    return (
      <Box width={1} display="flex" color="text.secondary" justifyContent="center" p={2}>
        <CircularProgress size={16} />
        <Box component="span" ml={2}>
          Loading...
        </Box>
      </Box>
    );
  }

  return (
    <Paper className={classes.paper}>
      <List dense className={classes.root}>
        {items.map((value, index) => {
          const labelId = `list-secondary-label-${value.name}`;
          const isSkill = tag.find(val => val.name === value.name);
          if (!!isSkill) {
            value = isSkill;
          }

          return (
            <ListItem
              onClick={() => handleSelect(value)}
              key={index}
              button
              disabled={!!tag.find(item => item.name === value.name)}>
              <ListItemText
                id={labelId}
                primary={value.name}
                style={{ color: `${!!tag.find(item => item.name === value.name) ? '#999999' : '#000'}` }}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
}
