export const Types = {
  GET_ANALYTICS: 'GET_ANALYTICS',
  GET_ANALYTICS_SUCCESS: 'GET_ANALYTICS_SUCCESS',
  GET_ANALYTICS_FAIL: 'GET_ANALYTICS_FAIL',
  SET_ANALYTICS_FILTER: 'SET_ANALYTICS_FILTER',
};

const INITIAL_STATE = {
  filter: null,
  analytics: {},
  loading: false,
  error: null,
};

const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_ANALYTICS:
      return { ...state, loading: true };
    case Types.GET_ANALYTICS_SUCCESS:
      return { ...state, loading: false, analytics: action.payload };
    case Types.GET_ANALYTICS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case Types.SET_ANALYTICS_FILTER:
      return { ...state, filter: action.payload };

    default:
      return { ...state };
  }
};

export const Actions = {
  getAnalytics: payload => ({
    type: Types.GET_ANALYTICS,
    payload,
  }),
  getAnalyticsSuccess: analytics => ({
    type: Types.GET_ANALYTICS_SUCCESS,
    payload: analytics,
  }),
  getAnalyticsFailed: error => ({
    type: Types.GET_ANALYTICS_FAIL,
    payload: error,
  }),
  setAnalyticsFilter: (filter, search) => ({
    type: Types.SET_ANALYTICS_FILTER,
    payload: { filter, search },
  }),
};

export default data;
